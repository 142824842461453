import {
  Component,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
  PLATFORM_ID,
  Inject,
  Input,
  ElementRef,
  ViewChild,
  afterNextRender,
} from '@angular/core';
import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-slideshow-promotion',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [RouterModule, CommonModule, NgOptimizedImage],
  templateUrl: './slideshow-promotion.component.html',
  styleUrl: './slideshow-promotion.component.scss',
})
export class SlideshowPromotionComponent implements OnInit {
  @ViewChild('swiperContainerPromo') swiperContainerRef!: ElementRef;
  @Input() data: any;
  @Input() type: any;

  public config: SwiperOptions = {};
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.swiperRender();
  }

  swiperRender() {
    afterNextRender((): void => {
      this.config = {
        loop: true,
        mousewheel: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        centeredSlides: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      Object.assign(this.swiperContainerRef.nativeElement, this.config); // Add parameters to the Swiper
      this.swiperContainerRef.nativeElement.initialize(); // Init Swiper
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      console.log(this.type);
      register();
    }
  }
}
