import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faLine, faLinkedin, faRedditAlien, faTelegram, faWhatsapp, faYoutube, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { faHeadphones, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contact-mobile',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule],
  templateUrl: './contact-mobile.component.html',
  styleUrl: './contact-mobile.component.scss'
})
export class ContactMobileComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private location: Location,
    public data: DataService,
    private router: Router,
  ) { }

  faFacebookF: IconProp = faFacebookF;
  faInstagram: IconProp = faInstagram;
  faLine: IconProp = faLine;
  faLinkedin: IconProp = faLinkedin;
  faReddit: IconProp = faRedditAlien;
  faTelegram: IconProp = faTelegram;
  faXTwitter: IconProp = faXTwitter;
  faWhatsapp: IconProp = faWhatsapp;
  faYoutube: IconProp = faYoutube;
  faHeadphones: IconProp = faHeadphones;
  faPhone: IconProp = faPhone;
  faArrowLeft: IconProp = faArrowLeft;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  websiteJSONData: any = this.data.websiteJSONData;
  livechatURL: string = '';

  goBack(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.router.navigate(['/']);
      // const currentUrl = window.location.href;
      // const referrerUrl = document.referrer;

      // const extractDomain = (url: string) => {
      //   try {
      //     const { hostname } = new URL(url);
      //     return hostname;
      //   } catch (e) {
      //     return null;
      //   }
      // };

      // const currentDomain = extractDomain(currentUrl);
      // const referrerDomain = extractDomain(referrerUrl);

      // if (currentDomain && referrerDomain && currentDomain === referrerDomain) {
      //   this.location.back();
      // } else {
      //   this.router.navigate(['/']);
      // }
    }
  }

  ngOnInit(): void {
    console.log(this.websiteJSONData);
    if (isPlatformBrowser(this.platformId)) {
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }
}
