import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { isPlatformBrowser } from '@angular/common';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile-information',
  standalone: true,
  imports: [MatFormFieldModule, MatButtonModule, MatInputModule, MatIconModule, FormsModule],
  templateUrl: './profile-information.component.html',
  styleUrl: './profile-information.component.scss'
})
export class ProfileInformationComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
  ) { }

  isSubmit: boolean = false;
  userProfileData: any = null;

  successMessage: string = '';
  errorMessage: string = '';
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  phone_number: string = '';

  editProfileSubmit(): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log(this.first_name, this.last_name, this.email, this.phone_number)
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.email || !this.first_name || !this.last_name || !this.phone_number) {
        this.errorMessage = 'All Field is Required.';
        this.isSubmit = false;
        return;
      } else {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email) === false) {
          this.errorMessage = 'Email is not Valid.';
          this.isSubmit = false;
          return;
        } else {
          this.request.editProfile(this.auth.sessionValue, this.userProfileData._id, this.first_name, this.last_name !== null ? this.last_name : '', this.userProfileData.nickname, this.email, this.phone_number, this.userProfileData.mobile_phone_country).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.auth.setUserData(v.data);
                this.isSubmit = false;
                this.router.navigate(['/profile'],{ queryParams: { part: 'default' } });
                this.successMessage = 'Edit Profile is Success.';
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;
            }
          })
        }
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.userProfileData = this.auth.userProfileData;
      this.first_name = this.userProfileData.first_name;
      this.last_name = this.userProfileData.last_name;
      this.email = this.userProfileData.email;
      this.phone_number = this.userProfileData.mobile_phone;

    }
  }
}
