@if(referralRule){
  @if(currentScreenSize != 'mobile'){
  <div class="page-referral">
    <app-header-simple />
    <div class="page-inner">
      <div class="container-xxl row m-auto">
        <div class="promotion-body">
          <div class=" sidebar-column">
          <app-sidebar-menu [active]="'referral'" />
        </div>
        <div class="col-9 pl-0 promotion-container content-container" id="promotion-container">
          <div class="promotion-inner h-100">
            <div class="promotion-title-custom">
              <div class="title-promotion">Referral</div>
            </div>
            <div class="page-inner-container">
              <div class="referral-container pt-2">
                @if(!showDownline){
                  <div class="">
                    @if (islogin) {
                      <div class="col-12 code-container text-center" (click)="copyReferralCode((getReferralPlayer?.referral_code))">
                        <p class="code-text">{{getReferralPlayer?.referral_code}}</p>
                        <p class="copy-code">Salin Kode Referral</p>
                      </div>
                    } @else {
                      <div class="col-12 code-container text-center">
                        <p class="code-text">Mohon Login</p>
                        <a class="copy-code-link" [routerLink]="['/profile']">Masuk</a>
                      </div>
                    }
                  </div>
                  <div class="container mt-4 ">
                    <div class="row">
                      <div class="col-md-12 col-lg-7 order-md-2 order-lg-1">
                        @if (islogin) {
                          <div class="w-100 content">
                            <div class="row justify-content-between">
                              <div class="col-12 p-0">
                                <div class="referral-box">
                                  <div class="referral-info">
                                    <span class="title">Url Kode Referral</span>
                                    <span class="value">{{baseUrl}}/?ref={{getReferralPlayer?.referral_code}}</span>
                                    <button class="btn" (click)="copyReferral((baseUrl+'/?ref='+getReferralPlayer?.referral_code))">SALIN</button>
                                  </div>
                                </div>
                              </div>
                                <div class="col-4 p-0" style="width: 48%;">
                                  <div class="referral-box" >
                                    <div class="referral-info">
                                      <span class="title">Total Penghasilan Keseluruhan</span>
                                      <span class="value">{{IDRFormat((getReferralPlayer?.earning).toString())}}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4 p-0 " style="width: 48%;">
                                  <div class="referral-box" >
                                    <div class="referral-info">
                                      <span class="title">Total Diklaim</span>
                                      <span class="value">{{IDRFormat((getReferralPlayer?.claimed_earning).toString())}}</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4 p-0 " style="width: 48%;">
                                  <div class="referral-box" >
                                    <div class="referral-info">
                                      <span class="title">Total Penghasilan</span>
                                      <span class="value">{{IDRFormat((getReferralPlayer?.earning  - getReferralPlayer?.claimed_earning).toString()) }}</span>
                                      <button class="btn" (click)="toggleReferralClaim()">CLAIM</button>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4 p-0 " style="width: 48%;">
                                  <div class="referral-box" >
                                    <div class="referral-info">
                                      <span class="title">Total Downline</span>
                                      <span class="value">{{getReferralPlayer?.total_downline}}</span>
                                      <button class="btn" (click)="toggleDownline()">DETAIL</button>
                                    </div>
                                  </div>
                                </div>
  
                                <div class="col-12 p-0 " >
                                  <div class="referral-box pengajuan-claim" (click)="toggleReferralClaim()">
                                    <span>Pengajuan Claim @if (isVisible) {<fa-icon [icon]="faCaretDown"></fa-icon>} @else {<fa-icon [icon]="faCaretUp"></fa-icon>}</span>
                                  </div>
                                </div>
                              </div>
                          </div>
                        }
                      </div>
                      <div class="col-md-12 col-lg-5 order-md-1 order-lg-2">
                        @if(referralRule.hide_rules == false) {
                          @if (referralRule.calculate_by_turnover || referralRule.calculate_by_win_lose) {
                            <div class="table-promotion-container">
  
                            </div>
                            <table class="referral-rules-table table-responsive-promotion">
                              <thead>
                                <tr>
                                  <th>Tipe Permainan</th>
                                  @if(referralRule.calculate_by_turnover){
                                    <th >Rollingan</th>
                                  }
                                  @if(referralRule.calculate_by_win_lose){
                                    <th >Menang/Kalah</th>
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                @for(item of tableRule | keyvalue ; track item ){
                                  <tr>
                                    <td>{{item.value['game_type']}}</td>
                                    @if(referralRule.calculate_by_turnover){
                                      <td >{{item.value['turnover_percentage']}}%</td>
                                    }
                                    @if(referralRule.calculate_by_win_lose){
                                      <td >{{item.value['winlose_percentage']}}%</td>
                                    }
                                  </tr>
                                }
                              </tbody>
                            </table>
                            <div class="earning-rules">
                              <span>** Sesuai Syarat Berikut ini:</span><br>
                              <ol>
                              @for(item of earningRules | keyvalue ; track item ){
                                <li>{{earningRulesText[item.key]}} {{customIDRFormat(item.value)}}</li>
                              }
                            </ol>
                              </div>
                          }
                        }
                      </div>
                    </div>
                  </div>
  
                  @if(islogin && isVisible){
                    <div class="col-12 claim-container text-center w-100" >
                        <div class="row">
                          <div class="col-9 input-container">
                            <div class="container-payment">
                              <div class="input-amount">
                                  <div class="d-inline-block w-100">
                                      <div class="container-input">
                                          <label for="inputAmount" class="label-input-small">Masukan Nominal</label>
                                          <input id="inputAmount" type="text" spellcheck="false" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" placeholder=" " [ngModel]="(inputAmount | currency:' ' : 'symbol' : '1.0-0')" (ngModelChange)="updateValueNominal($event)" name="inputAmount"
                                              aria-label="Masukan Nominal" />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <span class="max-button" matSuffix (click)="setMaxClaim()">Max</span>
                          </div>
                          <div class="col-3 button-claim-container">
                            <button class="btn  btn-sm btn-detail py-2 btn-claim"
                            [disabled]="buttonDisabled"
                            (click)="claim()">
                            Claim
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                  @if (islogin) {
                    <div class="col-12 mt-4">
                      <div class="table-promotion-container">
                        <table class="table table-promotion table-responsive table-responsive-promotion">
                          <thead>
                            <tr>
                              <th>Tanggal</th>
                              <th>Jumlah Klaim</th>
                            </tr>
                          </thead>
                          <tbody>
                            @for (item of getHistory; track item;) {
                              <tr>
                                <td>{{ dateFormat7(item.created_at)}} | {{hourFormat(item.created_at)}}</td>
                                <td>{{IDRFormat(item.claimed_amount)}}</td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      @if(getHistory.length != 0){
                        <mat-paginator
                          #paginator
                          (page)="handlePageEvent($event)"
                          [length]="lengthHistory"
                          [pageSize]="pageSize"
                          [showFirstLastButtons]="true"
                          [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                          [hidePageSize]="false"
                          [pageIndex]="pageIndexHistory-1"
                          aria-label="Select page"
                          class="promotion-paginator">
                          </mat-paginator>
                      }
                    </div>
                  }
                } @else {
                  <div class="">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <button class="btn btn-primary text-white navigation-button inter-bold px-3 py-2"
                          (click)="toggleDownline()">
                          <fa-icon class="arrow-right" [icon]="faCaretLeft"></fa-icon>
                          Back
                        </button>
                      </div>
                      <div class="col-4 text-center">
                        <div
                          class="text-white text-center month-current text-uppercase inter-bold d-flex align-items-center justify-content-center">
                          Downline
                        </div>
                      </div>
                      <div class="col-4"></div>
                      <p></p>
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <div class="table-promotion-container">
                      <table class="table table-promotion table-responsive table-responsive-promotion">
                        <thead>
                          <tr>
                            <th>Username</th>
                            <th>Tanggal Bergabung</th>
                            <th>Total Deposit</th>
                            <th>Total Turnover</th>
                            <th>Total Penghasilan</th>
                          </tr>
                        </thead>
                        <tbody>
                          @for (item of getReferralDownline; track item;) {
                            <tr>
                              <td>{{item.player_login_id}}</td>
                              <td>{{dateFormat(item.created_at)}}<br>{{hourFormat(item.created_at)}}</td>
                              <td>{{IDRFormat(item.total_deposit)}}</td>
                              <td>{{IDRFormat(item.total_turnover)}}</td>
                              <td>{{IDRFormat(item.earning)}}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                    @if(getReferralDownline.length != 0){
                      <mat-paginator
                        #paginatorDownline
                        (page)="handlePageEventDownline($event)"
                        [length]="lengthDownline"
                        [pageSize]="pageSize"
                        [showFirstLastButtons]="true"
                        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                        [hidePageSize]="false"
                        [pageIndex]="pageIndexDownline-1"
                        aria-label="Select page"
                        class="promotion-paginator">
                        </mat-paginator>
                    }
                  </div>
                }
              </div>
            </div>
  
          </div>
        </div>
        </div>
  
      </div>
    </div>
    <app-footer [data]="footerData" />
  </div>
  } @else {
    <app-referral-mobile></app-referral-mobile>
  }
}
