@if(!provider){
  @if(isLoading){
  <div class="card card-game loading-skeleton">
    <div class="card-game-top">
      <div class="ratio ratio-1x1">
        <div class="card-img-top"></div>
      </div>
    </div>
    <div class="card-body">
      <div>
        <div class="container-title">
          <h5 class="card-title"></h5>
          <div class="provider mb-0"></div>
        </div>
      </div>
    </div>
  </div>
  } @else {
  <div class="card card-game" (click)="clickPlay()">
    <div class="card-game-top">
      <div class="tags-game">
        @for(tag of info['tags']; track tag;){
          @if(tag !== 'BUYIN' && tag !== 'ANTE' && tag !== 'JACKPOT'){
            @if(info['tags'].length > 0 && $index < 3 && tag !==''){
              <span [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'">{{tag
            | tagName}}
            </span>
            }
          }
        }
      </div>
      <div class="">
        @if(image !== '' && image !== undefined) {
          <img [ngSrc]="image" [alt]="name" width="200" height="200" class="card-img-top" />
        } @else() {
          <div class="no-image">No Image</div>
        }
      </div>
    </div>
    <div class="card-body">
      <div>
        <div class="container-title">
          <div #cardTitle id="cardTitle" class="h5 card-title">
            <span #cardTitleText id="cardTitleText" [ngClass]="cardTitleWidth < cardTitleTextWidth ? 'animation': ''">{{ name }}</span>
          </div>
          <!-- <div #cardTitleMobile class="d-block d-md-none h5 card-title" [ngClass]="{'animation': cardTitleMobile ? cardTitleWidthMobile > (160 - 32) : false}">{{ name }}</div> -->
          <div class="provider mb-0">{{ providername }}</div>
        </div>
        <!-- <div class="card-side-bttn d-none d-md-flex flex-column gap-2">
            <button>
              <img src="../../../../template/green/assets/images/ic-pola.png" />
              Pola
            </button>
            <button>Demo</button>
          </div> -->
      </div>
    </div>
    @if(info['tags'].length > 0){
      <div class="d-flex card-body-bottom">
        <!-- <div class="container-tags"> -->
          <!-- <div class="tags-animation"> -->
            @for(tag of info['tags']; track tag;){
              @if(tag === 'BUYIN'){
                <div class="tag-bar d-flex p-0 m-0 w-100">
                  <img style="background: var(--alt-background-color-4);" src="../../../../template/green/assets/images/buyin_tag.png" />
                </div>
              }
              @if(tag === 'ANTE'){
                <div class="tag-bar d-flex p-0 m-0 w-100">
                  <img style="background: var(--alt-background-color-4);" src="../../../../template/green/assets/images/ante_tag.png" />
                </div>
              }
              @if(tag === 'JACKPOT'){
                <div class="tag-bar d-flex p-0 m-0 w-100">
                  <img style="background: var(--alt-background-color-2);" src="../../../../template/green/assets/images/jackpot_tag.png" />
                </div>
              }
            }
          <!-- </div> -->
        <!-- </div> -->
        @if(percent && percent > 0) {
          <!-- <div class="d-flex rtp w-100">RTP {{ percent }}%</div> -->
        }
      </div>

    }
    <div class="card-bottom">
      <button>Play Now</button>
    </div>
  </div>
  }
} @else {
  @if(isLoading){
  <div class="card card-game loading-skeleton">
    <div class="card-game-top">
      <div class="ratio ratio-1x1">
        <div class="card-img-top" ></div>
      </div>
    </div>
    <div class="card-body">
      <div>
        <div class="container-title">
          <h5 class="card-title"></h5>
          <div class="provider mb-0"></div>
        </div>
      </div>
    </div>
  </div>
  } @else {
    <div class="card card-game" (click)="clickProvider()">
      <div class="card-game-top">
        <div class="ratio ratio-1x1">
          @if(image !== '' && image !== undefined) {
            <img [ngSrc]="image" [alt]="name" width="200" height="200" class="card-img-top" />
          } @else() {
            <div class="no-image">No Image</div>
            }
        </div>
      </div>
      <!-- <div class="card-body">
        <div>
          <div class="container-title">
            <h5 class="card-title">{{ name }}</h5>
            <div class="provider mb-0">{{ providername }}</div>
          </div>
        </div>
      </div> -->
      <div class="card-bottom card-bottom-provider">
        <button>{{name}}</button>
      </div>
    </div>
  }
}
