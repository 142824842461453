@if(currentScreenSize != 'mobile'){
  <div class="page-promotion">
    <app-header-simple />
    <div class="page-inner">
      <div class="container-xxl row m-auto">
        <div class="promotion-body">
          <div class="sidebar-column">
            <app-sidebar-menu [active]="'contact'" />
          </div>
          <div class="col-9 pl-0 promotion-container content-container" id="promotion-container">
            <div class="page-inner-container promotion-inner h-100 pt-2">
              <div class="contact-container row px-4">
                <div class="title-section py-3">
                  <div class="contact-title">Contact Us</div>
                  <div class="contact-subtitle">
                    Silahkan hubungi salah satu dari kontak kami untuk mengubungi
                    Costumer Service kami apabila ada pertanyaan yang ingin
                    ditanyakan kepada Customer Service Kami
                  </div>
                </div>
                @if(websiteJSONData.contact.whatsapp !== '') {
                <div class="col-6 col-lg-4 mb-4">
                  <a
                    [href]="'https://wa.me/'+ websiteJSONData.contact.whatsapp_area_code + websiteJSONData.contact.whatsapp"
                    target="_blank"
                  >
                    <div class="contact-item">
                      <div class="contact-item-left icon-whatsapp">
                        <img src="../template/green/assets/images/contact/Whatsapp.webp" class="img-fluid"/>
                      </div>
                      <div class="contact-item-right">
                        <div class="info">
                          <div class="value">
                            {{ websiteJSONData.contact.whatsapp }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                }  @if(websiteJSONData.contact.telegram !== '') {
                <div class="col-6 col-lg-4 mb-4">
                  <a
                    [href]="'https://t.me/' + websiteJSONData.contact.telegram"
                    target="_blank"
                  >
                    <div class="contact-item py-3">
                      <div class="row">
                        <div class="col-3 text-center"><img src="../template/green/assets/images/contact/Telegram.webp" class="img-fluid"/></div>
                        <div class="col-8 d-flex align-items-center"><div class="info">
                          <div class="value">
                            {{ websiteJSONData.contact.telegram }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                }  @if(websiteJSONData.contact.line !== '') {
                <div class="col-6 col-lg-4 mb-4">
                  <a
                    [href]="'https://line.me/R/ti/p/' + websiteJSONData.contact.line"
                    target="_blank"
                  >
                  <div class="contact-item py-3">
                      <div class="row">
                        <div class="col-3 text-center"><img src="../template/green/assets/images/contact/Line.webp" class="img-fluid"/></div>
                        <div class="col-8 d-flex align-items-center"><div class="info">
                          <div class="value">
                            {{ websiteJSONData.contact.line }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                }  @if(websiteJSONData.contact.phone !== '') {
                <div class="col-6 col-lg-4 mb-4">
                  <a
                    [href]="
                      'tel:' +
                      websiteJSONData.contact.phone_area_code +
                      websiteJSONData.contact.linkedin
                    "
                  >
                  <div class="contact-item py-3">
                      <div class="row">
                        <div class="col-3 text-center"><img src="../template/green/assets/images/contact/Phone.webp" class="img-fluid"/></div>
                        <div class="col-8 d-flex align-items-center"><div class="info">
                          <div class="value">
                            {{ websiteJSONData.contact.phone_area_code
                            }}{{ websiteJSONData.contact.phone }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                }
                <div class="title-section py-3">
                  <div class="contact-title">Follow Us</div>
                  <div class="contact-subtitle">
                    Follow semua sosial media kami untuk mendapatkan berita terbaru mengenai promo, event, game terbaru dan lainnya
                  </div>
                </div>
                <div class="social-container row">
    @if(websiteJSONData.contact.twitter !== '') {
                <div class="col-6 col-lg-3 mb-4">
                  <a
                    [href]="
                      'https://twitter.com/' + websiteJSONData.contact.twitter
                    "
                    target="_blank"
                  >
                  <div class="social-item">
                      <div class="row">
                        <div class="col-3 text-center p-2 twitter-logo contact-logo d-flex justify-content-center align-items-center"><img src="../template/green/assets/images/contact/Livechat.webp" class="img-fluid"/></div>
                        <div class="col-9 d-flex align-items-center justify-content-center text-social"><div class="info">
                          <div class="value h6 fw-bold m-0">
                            {{ websiteJSONData.contact.twitter }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                }@if(websiteJSONData.contact.facebook !== '') {
                  <div class="col-6 col-lg-3 mb-4">
                  <a
                    [href]="
                      'https://www.facebook.com/people/' +
                      websiteJSONData.contact.facebook
                    "
                    target="_blank"
                  >
                  <div class="social-item">
                      <div class="row">
                        <div class="col-3 text-center p-2 facebook-logo contact-logo d-flex justify-content-center align-items-center"><fa-icon [icon]="faFacebookF" [size]="'2x'"></fa-icon></div>
                        <div class="col-9 d-flex align-items-center justify-content-center text-social"><div class="info">
                          <div class="value h6 fw-bold m-0">
                            {{ websiteJSONData.contact.facebook }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                } @if(websiteJSONData.contact.instagram !== '') {
                <div class="col-6 col-lg-3 mb-4">
                  <a
                    [href]="
                      'https://www.instagram.com/' +
                      websiteJSONData.contact.instagram
                    "
                    target="_blank"
                  >
                  <div class="social-item">
                      <div class="row">
                        <div class="col-3 text-center p-2 instagram-logo contact-logo d-flex justify-content-center align-items-center"><fa-icon [icon]="faInstagram" [size]="'2x'"></fa-icon></div>
                        <div class="col-9 d-flex align-items-center justify-content-center text-social"><div class="info">
                          <div class="value h6 fw-bold m-0">
                            {{ websiteJSONData.contact.instagram }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                }  @if(websiteJSONData.contact.linkedin !== '') {
                <div class="col-6 col-lg-3 mb-4">
                  <a
                    [href]="
                      'https://www.linkedin.com/in/' +
                      websiteJSONData.contact.linkedin
                    "
                    target="_blank"
                  >
                  <div class="social-item">
                      <div class="row">
                        <div class="col-3 text-center p-2 linkedin-logo contact-logo d-flex justify-content-center align-items-center"><fa-icon [icon]="faLinkedin" [size]="'2x'"></fa-icon></div>
                        <div class="col-9 d-flex align-items-center justify-content-center text-social"><div class="info">
                          <div class="value h6 fw-bold m-0">
                            {{ websiteJSONData.contact.linkedin }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                }   @if(websiteJSONData.contact.reddit !== '') {
                <div class="col-6 col-lg-3 mb-4">
                  <a
                    [href]="
                      'https://www.reddit.com/' + websiteJSONData.contact.reddit
                    "
                    target="_blank"
                  >
                    <div class="social-item">
                      <div class="row">
                        <div class="col-3 text-center p-2 reddit-logo contact-logo d-flex justify-content-center align-items-center"><fa-icon [icon]="faReddit" [size]="'2x'"></fa-icon></div>
                        <div class="col-9 d-flex align-items-center justify-content-center text-social"><div class="info">
                          <div class="value h6 fw-bold m-0">
                            {{ websiteJSONData.contact.reddit }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                }   @if(websiteJSONData.contact.youtube !== '') {
                <div class="col-6 col-lg-3 mb-4">
                  <a
                    [href]="
                      'https://www.youtube.com/channel/' +
                      websiteJSONData.contact.youtube
                    "
                    target="_blank"
                  >
                    <div class="social-item">
                      <div class="row">
                        <div class="col-3 text-center p-2 youtube-logo contact-logo d-flex justify-content-center align-items-center "><fa-icon [icon]="faYoutube" [size]="'2x'"></fa-icon></div>
                        <div class="col-9 d-flex align-items-center justify-content-center text-social"><div class="info">
                          <div class="value h6 fw-bold m-0">
                            {{ websiteJSONData.contact.youtube }}
                          </div>
                        </div></div>
                      </div>
                    </div>
                  </a>
                </div>
                }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <app-footer [data]="footerData" />
    </div>
} @else {
  <app-contact-mobile></app-contact-mobile>
}

