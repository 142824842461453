import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FooterComponent } from '@template/green/footer/footer.component';
import { AllGamesComponent } from '@template/green/parts/all-games/all-games.component';
import { DataService } from '@componentpath/services/data.service';
import { SidebarComponent } from '@template/green/parts/sidebar/sidebar.component';
import { Slideshow1Component } from '@template/green/parts/slideshow1/slideshow1.component';
import { HeaderSearchComponent } from '@template/green/header-search/header-search.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HeaderMobileComponent } from '@template/green/mobile/header-mobile/header-mobile.component';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';

@Component({
  selector: 'app-provider',
  standalone: true,
  imports: [
    CommonModule,
    HeaderSearchComponent,
    FooterComponent,
    AllGamesComponent,
    SidebarComponent,
    Slideshow1Component,
    HeaderMobileComponent
  ],
  templateUrl: './provider.component.html',
  styleUrl: './provider.component.scss'
})
export class ProviderComponent implements OnInit {
  @ViewChild(MatDrawer)
  drawer!: MatDrawer;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: Document,
    public dataService: DataService,
   private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  currentScreenSize: string = '';
  websiteJSONData: any = this.dataService.websiteJSONData;

  currentSideBarMode: MatDrawerMode = "side";

  isSideNavOpened: any = true;

  showSideBar: boolean = false;

  currentParamsCat: any;
  currentParamsTag: any;

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 799.98px)", 'smallOne'],
      ["(min-width: 800px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 799.98px)",
      "(min-width: 800px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
          if (this.currentScreenSize === 'xSmall') {
            this.currentSideBarMode = "over";
          }
          if (this.currentScreenSize === 'smallOne') {
            this.currentSideBarMode = "over";
          }
          if (this.currentScreenSize === 'smallTwo') {
            this.currentSideBarMode = "side";
            this.isSideNavOpened = true;
          }
          if (this.currentScreenSize === 'customTablet') {
            this.currentSideBarMode = "side";
            this.isSideNavOpened = true;
          }
          if (this.currentScreenSize === 'desktop') {
            this.currentSideBarMode = "side";
            this.isSideNavOpened = true;
          }
        }
      }
    });
  }

  backdropClick() {
    this.drawer.close();
    this.currentSideBarMode = 'side';
    this.isSideNavOpened = false;
    setTimeout(() => {
      this.drawer.open();
    }, 500)
  }
  showClick() {
    this.showSideBar = true;
  }
  hideClick() {
    this.showSideBar = false;
  }

  slideshowJSONData: any = this.dataService.slideshowJSONData;
  slideshowData: any = [];
  providerLists: Array<any> = [];

  checkParams() {
    let paramsDoc = new URL(document.location.toString()).searchParams;
    let typesDoc = paramsDoc.get("categories");
    let tagsDoc = paramsDoc.get("tags");
    this.currentParamsTag = undefined;
    this.currentParamsCat = undefined;

    if (tagsDoc) {
      let arrTags = JSON.parse(decodeURIComponent(tagsDoc));
      if (arrTags.length === 1) {
        this.currentParamsTag = arrTags[0];
      } else {
        this.currentParamsTag = undefined;
      }
    }

    if (typesDoc) {
      let arrTypes = JSON.parse(decodeURIComponent(typesDoc));
      if (arrTypes.length === 1 && this.currentParamsTag === undefined) {
        this.currentParamsCat = arrTypes[0];
      } else {
        this.currentParamsCat = undefined;
      }
    }
    console.log(this.currentParamsTag)
    console.log(this.currentParamsCat)

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkParams();
      this.providerLists = this.dataService.providerJSONData['records'];
      // for (const value of this.slideshowJSONData.records) {
      //   if (value.code === 'HOMEPAGE') {
      //     this.slideshowData = value.contents;
      //     break;

      //   }
      // }
      setTimeout(() => {
        this.doc.body.scrollTo(0, 0) //temporary deleted soon
      }, 200);
    }
  }
}