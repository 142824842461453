<div class="container-xxl mx-auto">
  <swiper-container appSwiper [config]="config" class="swiper-container">
    @for (item of bankList; track item._id;) {
      @if(item.code !== 'oth' && item.code !== 'other') {
        <swiper-slide class="swiper-item">
          <!-- @if(item.code === 'oth') {
          <img
            src="../../../../template/green/assets/images/bank/bca.svg"
            [title]="item.code"
          />
          }  -->
          <img
            [src]="
              '../../../../template/green/assets/images/bank/' + item.code + '.svg'
            "
            [title]="item.code"
          />
        </swiper-slide>
      }
    }
  </swiper-container>
</div>
