@if(data.length>0){
<swiper-container init="false" #swiperContainerPromo class="swiper-container">
  @for (item of data; track item.order;) {
  <swiper-slide class="swiper-item" [ngClass]="type === 1 ? 'width-full':''">
    @if(item['banner_url'] !== '' && item['banner_url'] !== '#') {
    @if(item.link_type === 1){
    <a [routerLink]="['/promotion']" [queryParams]="{ part: item._id }">
      <!-- <img [src]="item.asset_url" [title]="item.title" /> -->
      <img [ngSrc]="item['thumbnail_url']" width="935" height="390" [title]="item.title" priority />
    </a>
    }@else{
    <a [routerLink]="['/event']" [queryParams]="{ part: item._id }">
      <!-- <img [src]="item.asset_url" [title]="item.title" /> -->
      <img [ngSrc]="item['thumbnail_url']" width="935" height="390" [title]="item.title" priority />
    </a>
    }
    }
  </swiper-slide>
  }
</swiper-container>
}