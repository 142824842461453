<div class="jackpot px-0 mx-0">
  <div class="px-0 mx-0 jackpot-inner">
    <span class="value" #a>
      <div class="blink-text" style="font-size: 3vw; margin-right: 60px;">IDR {{jackpotGlobalVal | formatNumberId}}</div>
    </span>
    @if(websiteJSONData.misc.jackpot === 'ironman'){
    <img ngSrc="../../../template/green/assets/images/jackpot-ironman.gif" width="951" height="136" priority />
    
    }@else{
    <img ngSrc="../../../assets/orianna/images/jackpot_desktop.gif" width="951" height="136" priority />
    }
  </div>
</div>
