import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '@componentpath/services/data.service';
import { AllGamesComponent } from '@template/green/parts/all-games/all-games.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, MatButtonModule, RouterModule, AllGamesComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  websiteJSONData: any = this.data.websiteJSONData;
  activeMenu: string = '';
  currentScreenSize: string = 'desktop';
  currentSideBarMode: MatDrawerMode = "side";
  constructor(
    private router: Router,
    private data: DataService,
    private breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute
  ) {
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      [Breakpoints.Small, 'small'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
          if (this.currentScreenSize === 'xSmall') {
            this.currentSideBarMode = "over"
          }
          if (this.currentScreenSize === 'small') {
            this.currentSideBarMode = "over"
          }
          if (this.currentScreenSize === 'customTablet') {
            this.currentSideBarMode = "over"
          }
          if (this.currentScreenSize === 'desktop') {
            this.currentSideBarMode = "side"
          }

          console.log(this.currentScreenSize);
        }
      }
    });
  }

  clickMenu(menu: string, isGameType: boolean, menuType: string) {
    if (this.activeMenu === menu) {
      this.activeMenu = '';
    } else {
      this.activeMenu = menu;
    }
    if (menu) {
      if (isGameType) {
        this.router.navigate(['/search'], {
          queryParams: {
            categories: encodeURIComponent(JSON.stringify([menu])),
          },
        });
      } else {
        if (menuType) {
          this.router.navigate(['/search'], {
            queryParams: {
              tags: encodeURIComponent(JSON.stringify([menu])),
              categories: encodeURIComponent(JSON.stringify([menuType])),
            },
          });
        } else {
          this.router.navigate(['/search'], {
            queryParams: {
              tags: encodeURIComponent(JSON.stringify([menu])),
            },
          });
        }
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe((params) => {
        if (params['categories'] || params['tags']) {
          if (params['categories']) {
            let types = JSON.parse(decodeURIComponent(params['categories']));
            if (types.length === 1) {
              this.activeMenu = types[0];
            } else {
              this.activeMenu = '';
            }
          }
          if (params['tags']) {
            let tags = JSON.parse(decodeURIComponent(params['tags']));
            if (tags.length === 1) {
              this.activeMenu = tags[0];
            } else {
              this.activeMenu = '';
            }
          }
        } else {
          this.activeMenu = '';
        }
      });
    }
  }
}
