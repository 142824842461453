@if(data.length>0){
<swiper-container init="true" #swiperContainer class="swiper-container">
  @for (item of slideshowJSONData; track item.order;) {
  <swiper-slide class="swiper-item">
    @if(item['asset_url'] !== '' && item['asset_url'] !== '#') {
      @if(item.link_type === 1){
      <a [routerLink]="['/promotion']" [queryParams]="{ part: item.article }">
        <!-- <img [src]="item.asset_url" [title]="item.title" /> -->
        <img [ngSrc]="item['asset_url']" width="935" height="390" [title]="item.title" priority />
      </a>
      }@else{
      <a [href]="item.target_url" target="_blank">
        <!-- <img [src]="item.asset_url" [title]="item.title" /> -->
        <img [ngSrc]="item['asset_url']" width="935" height="390" [title]="item.title" priority />
      </a>
    }
    }
  </swiper-slide>
  }
</swiper-container>
}