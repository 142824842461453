@if(currentScreenSize != 'mobile'){
  <div class="page-promotion">
    <app-header-simple />
    <div class="page-inner">
      <div class="container-xxl row m-auto">
        <div class="promotion-body mb-5">
          <div class="sidebar-column">
            <app-sidebar-menu [active]="'transaction'" />
          </div>
          <div class="col-9 pl-0 promotion-container content-container" id="promotion-container">
            <div class=" promotion-inner h-100">
              <div class="promotion-title-custom">
                @if(this.transactionDetailLoading === null){
                  <div class="title-promotion">Transaksi</div>
                  <div class="subtitle-promotion">Max Data : 3 Bulan</div>
                } @else {
                  <div class="title-promotion">Detail</div>
                }

              </div>
              <div class="page-inner-container">
                <div class="saldo-container row text-white">
                  @if(this.transactionDetailLoading === null) { <div
                    class="row mb-4 mx-0">
                    <div class="col-4 ps-0">
                      <mat-form-field style="width: 100%; margin-top: 1rem">
                        <mat-label>Tipe</mat-label>
                        <mat-select [(value)]="type"
                          (selectionChange)="typeChange($event)">
                          <mat-option value="0">Semua</mat-option>
                          <mat-option value="1">Deposit</mat-option>
                          <mat-option value="2">Withdrawal</mat-option>
                          <mat-option value="3">Transfer In</mat-option>
                          <mat-option value="4">Transfer Out</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-4 pe-0">
                      <mat-form-field style="width: 100%; margin-top: 1rem">
                        <mat-label>Rentang Tanggal</mat-label>
                        <mat-select [(value)]="filter_created_at"
                          (selectionChange)="dateChange($event)">
                          <mat-option value="">Semua</mat-option>
                          <mat-option value="today">Hari Ini</mat-option>
                          <mat-option value="yesterday">Kemarin</mat-option>
                          <mat-option value="weekNow">Minggu Ini</mat-option>
                          <mat-option value="weekBefore"
                          >Minggu Kemarin</mat-option>
                          <mat-option value="monthNow">Bulan Ini</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-4 pe-0">
                      <mat-form-field style="width: 100%; margin-top: 1rem">
                        <mat-label>Status</mat-label>
                        <mat-select [(value)]="status"
                          (selectionChange)="statusChange($event)">
                          <mat-option value="">All</mat-option>
                          <mat-option value="0">Pending</mat-option>
                          <mat-option value="1">On Hold</mat-option>
                          <mat-option value="2">Approved</mat-option>
                          <mat-option value="3">Rejected</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="px-3">
                    <div class="table-promotion-container">
                      <table class="table table-promotion table-responsive-promotion">
                        <thead>
                          <tr>
                            <th scope="col">Tipe</th>
                            <th scope="col">Jumlah</th>
                            <th scope="col">Status</th>
                            <th scope="col" class="text-end">Tanggal</th>
                            <th scope="col" class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody> @if(transaction === null) { <tr>
                            <td colspan="5" align="center">
                              <div class="spinner-border"></div>
                            </td>
                          </tr> } @else { @if(transaction.data.total_records === 0) {
                          <tr>
                            <td colspan="5" align="center">No Data</td>
                          </tr> } @else { @for (item of transaction.data.records;
                          track item;) { <tr>
                          <td><img src="../template/green/assets/images/key.webp" class="img-fluid copy-icon" (click)="copyToClipboard(item._id)"/>{{ typeLabel(item.type) }}</td>
                            <td>IDR {{ IDRFormat(item.amount) }}</td>
                            <td [ngClass]="{
                                'negative-amount': item.status == 3,
                                'positive-amount': item.status == 2,
                                'pending-status-color': item.status == 0
                              }"> {{ statusLabel(item.status) }} </td>
                            <td class="text-end">{{ dateFormat(item.created_at) }} <br />
                              {{ hourFormat(item.created_at) }}</td>
                            <td class="text-center">
                              <button class="btn btn-primary btn-sm btn-detail d-none d-lg-block"
                                (click)="clickDetail(item._id)"> Detail </button>
                                <button class="btn btn-primary btn-sm btn-detail btn-icon float-end d-block d-lg-none"
                                (click)="clickDetail(item._id)">
                                <fa-icon class="arrow-right" [icon]="faCaretRight"></fa-icon>
                              </button>
                            </td>
                          </tr> } } } </tbody> @if(transaction !== null) {
                        }
                      </table>
                    </div>
                    @if(transaction != null){
                      <mat-paginator
                    #paginator
                    (page)="handlePageEvent($event)"
                    [length]="length"
                    [pageSize]="pageSize"
                    [showFirstLastButtons]="true"
                    [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                    [hidePageSize]="false"
                    [pageIndex]="pageIndex-1"
                    aria-label="Select page"
                    class="promotion-paginator">
                     </mat-paginator>
                    }
                  </div> } @else { @if(this.transactionDetailLoading === true) {
                    <div class="row mb-4 mx-2 p-4">
                      <div class="spinner-border m-auto"></div>
                  </div>
                  } @else {
                    <div class="detail-body px-3">
                      <div class="w-100">
                        <button class="back bt text-white navigation-button inter-bold w-auto float-start my-3"
                        (click)="clickBack()">
                        <fa-icon class="arrow-right" [icon]="faCaretLeft"></fa-icon>
                        BACK </button>
                      </div>

                      <div class="w-100 mt-3 content">
                        <div class="row">
                          @if(transactionDetail.data.status == 3 && transactionDetail.data.processing_note != '') {
                            <div class="col-12">
                              <div class="reject-reason" >
                                <span class="reason-text">{{transactionDetail.data.processing_note}}</span>
                              </div>
                            </div>
                          }
                          <div class="col-6 ">
                            <div class="transaction-box mr-2">
                              <img src="../template/green/assets/images/key.webp" class="img-fluid key-icon"/>
                              <div class="transaction-info">
                                <span class="transaction-text">Transaksi</span>
                                <span class="transaction-type-text">{{typeLabel(this.transactionDetail.data.type)}}</span>
                              </div>
                            </div>
                          </div>
                        <div class="col-6">
                          <div class="transaction-box text-center transaction-status" [ngClass]="{
                            'reject-status': this.transactionDetail.data.status == 3,
                            'approve-status': this.transactionDetail.data.status == 2,
                            'pending-status': this.transactionDetail.data.status == 0
                          }">
                            <span class="transaction-status-text">{{ statusLabel(this.transactionDetail.data.status) }}</span>
                          </div>
                        </div>
                        @if(transactionDetail.data.from_bank == undefined || transactionDetail.data.type == 2){
                          <div class="col-12 inter-bold mb-1" style="margin-left: 10px;"> Rekening Penerima</div>
                        <div class="col-6">
                          <div class="transaction-box">
                            <div class="account-info">
                              <span class="title">Nama Rekening</span>
                              <span class="value">@if(this.transactionDetail.data.to_bank.account_name){{{this.transactionDetail.data.to_bank.account_name}}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="transaction-box">
                            <div class="account-info">
                              <span class="title">Bank Rekening</span>
                              <span class="value">{{this.transactionDetail.data.to_bank.system_bank.name}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="transaction-box">
                            <div class="account-info">
                              <span class="title">Nomor Rekening</span>
                              <span class="value">{{this.transactionDetail.data.to_bank.account_number}}</span>
                            </div>
                          </div>
                        </div>
                        } @else {
                          @if(this.transactionDetail.data.to_bank){
                            <div class="col-6 ">
                              <div class="deposit-box mr-2">
                                <div class="mb-2">
                                  <span class="account">Rekening Tujuan</span>
                                </div>
                                <div class="deposit-info">
                                  <span class="title">Nama Rekening</span>
                                  <span class="value">@if(this.transactionDetail.data.to_bank.account_name){{{this.transactionDetail.data.to_bank.account_name}}}</span>
                                </div>
                                <div class="deposit-info">
                                  <span class="title">Bank Rekening</span>
                                  <span class="value">{{this.transactionDetail.data.to_bank.system_bank.name}}</span>
                                </div>
                                <div class="deposit-info">
                                  <span class="title">Nomor Rekening</span>
                                  <span class="value">{{(this.transactionDetail.data.to_bank.account_number)}}</span>
                                </div>
                              </div>
                            </div>
                          }

                          @if(this.transactionDetail.data.from_bank){
                            <div class="col-6 ">
                              <div class="deposit-box mr-2">
                                <div class="mb-2">
                                  <span class="account">Rekening Pengirim</span>
                                </div>
                                <div class="deposit-info">
                                  <span class="title">Nama Rekening</span>
                                  <span class="value">{{this.transactionDetail.data.from_bank.account_name}}</span>
                                </div>
                                <div class="deposit-info">
                                  <span class="title">Bank Rekening</span>
                                  <span class="value">{{this.transactionDetail.data.from_bank.system_bank.name}}</span>
                                </div>
                                <div class="deposit-info">
                                  <span class="title">Nomor Rekening</span>
                                  <span class="value">{{(this.transactionDetail.data.from_bank.account_number)}}</span>
                                </div>
                              </div>
                            </div>
                          }


                        }
                        @if(transactionDetail.data.transfer_slip != null || transactionDetail.data.additional_file != null){
                          <div class="col-6 d-flex justify-content-center align-items-center mb-2">
                            @if (transactionDetail.data.transfer_slip != null) {
                              <img src="{{transactionDetail.data.transfer_slip}}" class="img-fluid scaled-image" alt="transfer-slip">
                            }
                          </div>
                          <div class="col-6 d-flex justify-content-center align-items-center mb-2">
                            @if (transactionDetail.data.additional_file != null) {
                              <img src="{{transactionDetail.data.additional_file}}" class="img-fluid scaled-image" alt="additional_file">
                            }
                          </div>
                        }
                        <div class="col-12 ">
                          <div class="transaction-box d-flex justify-content-end py-3">
                            <div class=" payment-info">
                              <table class="text-right">
                                <tr>
                                  <td>Jumlah</td>
                                  <td>Rp {{IDRFormat(this.transactionDetail.data.amount)}}</td>
                                </tr>
                                <tr>
                                  <td>Biaya Transaksi</td>
                                  <td>Rp {{IDRFormat(this.transactionDetail.data.transaction_fee)}}</td>
                                </tr>
                                <tr><td><p></p></td><td></td></tr>
                                <tr>
                                  <td class="total-text">Total Diterima</td>
                                  <td class="total-text">Rp {{IDRFormat(this.transactionDetail.data.amount -
                                    this.transactionDetail.data.transaction_fee)}}</td>
                                </tr>
                              </table>
                            </div>
                          </div>

                        </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div class="px-3">
                    <div class="wrapper ">
                      <table class="table table-responsive table-status table-responsive-promotion">
                        <thead>
                          <tr>
                            <th scope="col">Status</th>
                            <th scope="col">Tanggal</th>
                            <th scope="col">IP Address</th>
                          </tr>
                        </thead>
                        <tbody> @if(this.transactionDetailLoading === true) { <tr>
                            <td colspan="3" align="center">
                              <div class="spinner-border"></div>
                            </td>
                          </tr> } @else {
                          @if(this.transactionDetail.data.histories.length === 0) {
                          <tr>
                            <td colspan="3" align="center">No Data</td>
                          </tr> } @else { @for (item of
                          this.transactionDetail.data.histories; track item;) { <tr>
                            <td [ngClass]="{
                              'negative-amount': item.status == 3,
                              'positive-amount': item.status == 2,
                              'pending-status-color': item.status == 0
                            }">{{ statusLabel(item.status) }}</td>
                            <td>{{ dateFormat(item.created_at) }} | {{ hourFormat(item.created_at) }}</td>
                            <td>{{ item.ip }}</td>
                          </tr> } } } </tbody>
                      </table>
                    </div>
                  </div> }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <app-footer [data]="footerData" />
    </div>
  </div>
} @else {
  <app-transaction-mobile />
}
