import { isPlatformBrowser,CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  CUSTOM_ELEMENTS_SCHEMA,
  OnDestroy,
  Renderer2, ViewChild, ElementRef,
  afterNextRender
} from '@angular/core';
// import { HeaderComponent } from '@template/green/header/header.component';
import { SlideshowHomeComponent } from '@template/green/parts/slideshow-home/slideshow-home.component';
import { SlideshowPromotionComponent } from '@template/green/parts/slideshow-promotion/slideshow-promotion.component';
import { JackpotComponent } from '@template/green/parts/jackpot/jackpot.component';
import { AllGamesHomeComponent } from '@template/green/parts/all-games-home/all-games-home.component';
import { FooterComponent } from '@template/green/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { db } from '@componentpath/db/db';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginHomeComponent } from '@template/green/parts/login-home/login-home.component';
import { FormatNumberIdPipe } from '@componentpath/pipes/format-number-id.pipe';
import { FormatDateIdPipe } from '@componentpath/pipes/format-date-id.pipe';
import { FormatTimeIdPipe } from '@componentpath/pipes/format-time-id.pipe';
import { RouterModule } from '@angular/router';
import { Router } from 'express';
import { GamesWidgetComponent } from '@template/green/parts/games-widget/games-widget.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HomeMobileComponent } from '@template/green/mobile/home-mobile/home-mobile.component';
import { HomeTabletComponent } from "@template/green/tablet/home-tablet/home-tablet.component";
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
import { SwiperOptions } from 'swiper/types';
import { register } from 'swiper/element';

@Component({
  selector: 'app-home',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    FontAwesomeModule,
    HeaderSimpleComponent,
    SlideshowHomeComponent,
    JackpotComponent,
    AllGamesHomeComponent,
    FooterComponent,
    LoginHomeComponent,
    GamesWidgetComponent,
    FormatNumberIdPipe,
    FormatDateIdPipe,
    FormatTimeIdPipe,
    SlideshowPromotionComponent,
    HomeMobileComponent,
    CommonModule,
    RouterModule,
    HomeTabletComponent
],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements  OnInit, OnDestroy {
  @ViewChild('swiperContainer') swiperContainerRef!: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public dataService: DataService,
    private requestService: RequestService,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2
  ) {
    this.checkScreen();
    this.swiperRender();
  }

  public configSlideshowHome: SwiperOptions = {};
  swiperRender() {
    afterNextRender((): void => {
      this.configSlideshowHome = {
        mousewheel: true,
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: 10,
        centeredSlides: false,
        pagination: false,
        on: {
          init() {
          },
        },
      };

      if (this.swiperContainerRef){
        Object.assign(this.swiperContainerRef.nativeElement, this.configSlideshowHome); // Add parameters to the Swiper
        this.swiperContainerRef.nativeElement.initialize(); // Init Swiper
      }
    });
  }


  @ViewChild('shakeImage', { static: false }) shakeImage: ElementRef | undefined;
  @ViewChild('shakeSpan', { static: false }) shakeSpan: ElementRef | undefined;
  private livescoreIntervalId: any;
  private shakeIntervalId: any;
  slideshowJSONData: Array<any> = [];
  promoJSONData: any = [];
  footerJSONData: any = this.dataService.footerJSONData;
  footerData: any = [];
  popularGamesData: any = [];
  faAngleRight: IconProp = faAngleRight;
  websiteJSONData: any = this.dataService.websiteJSONData;
  categoryProviderData: any = this.dataService.categoryproviderJSONData;
  hideLiveScore:boolean = true;
  betList: any = [
    {
      team1: 'M.City',
      team2: 'M.Utd',
      winner: 'M.Utd',
      totalBet: 20000,
    },
    {
      team1: 'B.Munich',
      team2: 'BVB',
      winner: 'B.Munich',
      totalBet: 20000,
    },
    {
      team1: 'Liv',
      team2: 'Ars',
      winner: 'Liv',
      totalBet: 20000,
    },
  ];
  sabaBetList: any = [
    {
      team1: 'Che',
      team2: 'Tot',
      winner: 'Che',
      totalBet: 20000,
    },
    {
      team1: 'Barca',
      team2: 'A.Madrid',
      winner: 'Barca',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
    {
      team1: 'A.Villa',
      team2: 'W.Ham',
      winner: 'W.Ham',
      totalBet: 20000,
    },
  ];
  bigMatchData: any = {
    live: true,
    phase : 1,
    match_start_at : "2024-08-02T14:00:00Z",
    league : "UEFA Champions League",
    league_name : "UEFA Champions League",
    home_team: '',
    home_team_name: 'Real Madrid',
    home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
    home_score: 1,
    away_team: '',
    away_team_name: 'Rayo Vallecano',
    away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
    away_score: 0,
  }

  otherMatchData: any = [
    {
      live: true,
      phase : 1,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 2,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 3,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 4,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 5,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 6,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 7,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },
    {
      live: true,
      phase : 9,
      match_start_at : "2024-08-02T14:00:00Z",
      league : "UEFA Champions League",
      league_name : "UEFA Champions League",
      home_team: '',
      home_team_name: 'Real Madrid',
      home_team_image : '../../../template/green/assets/images/Real_Madrid.png',
      home_score: 1,
      away_team: '',
      away_team_name: 'Rayo Vallecano',
      away_team_image : '../../../template/green/assets/images/Rayo_Vallecano.png',
      away_score: 0,
    },

  ];
  listPromo: any = [
    {
      id: 1,
      title: 'Banner 1',
      image: '../assets/orianna/images/banner1.png',
    },
    {
      id: 2,
      title: 'Banner 2',
      image: '../assets/orianna/images/banner2.png',
    },
    {
      id: 3,
      title: 'Banner 3',
      image: '../assets/orianna/images/banner3.png',
    },
    {
      id: 4,
      title: 'Banner 4',
      image: '../assets/orianna/images/banner4.png',
    },
  ];

  currentScreenSize: string = 'desktop';


  getOpacity(homeScore: number, awayScore: number, team: 'home' | 'away'): string {
    if (team === 'home' && homeScore < awayScore) {
      return '0.5';
    } else if (team === 'away' && awayScore < homeScore) {
      return '0.5';
    }
    return '1';
  }

  shakeElements(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.shakeImage && this.shakeSpan) {
        this.renderer.addClass(this.shakeImage.nativeElement, 'shake');
        this.renderer.addClass(this.shakeSpan.nativeElement, 'shake');

        setTimeout(() => {
          this.renderer.removeClass(this.shakeImage!.nativeElement, 'shake');
          this.renderer.removeClass(this.shakeSpan!.nativeElement, 'shake');
        }, 500);
      }
  }
  }

  getMinuteDifference(targetTime: string): number {
    const targetDate = new Date(targetTime);
    const currentDate = new Date();

    const diffInMs = targetDate.getTime() - currentDate.getTime();
    const diffInMinutes = Math.round(diffInMs / (1000 * 60));
    return diffInMinutes;
  }

checkScreen() {
  let displayNameMap = new Map([
    [Breakpoints.XSmall, 'xSmall'],
    [Breakpoints.Small, 'small'],
    ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
  ]);
  this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
    "(min-width: 960px) and (max-width: 1365.98px)",
    "(min-width: 1366px)"
  ]).subscribe(result => {
    // console.log(result)
    for (const query of Object.keys(result.breakpoints)) {
      if (result.breakpoints[query]) {
        this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
      }
    }
    });
  }

  private popularGames() {
    // db.dbGamesExists().then((e) => {});
    if (isPlatformBrowser(this.platformId)) {
      this.requestService
        .gamesJSON(1, 10, [''], [''], [''], '', 'popularity_point', 'desc')
        .subscribe({
          next: (v) => {
            this.popularGamesData = v['items'];
            // db.addGames(v['items']);
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => {},
        });
    }
  }

  //get only global false
  public filterPromotion() {
    for (const value of this.dataService.promoJSONData as any[]) {
      if (!value.global && this.promoJSONData.length < 4) {
        this.promoJSONData.push(value);
      }
    }

    console.log(this.promoJSONData);
  }

  public filterSlideshow() {
    for (const value of this.dataService.slideshowJSONData as Array<any>) {
      let tagsValue = value.tag as Array<string>;
      if (!value.global && tagsValue.includes('HOMEPAGE')) {
        this.slideshowJSONData.push(value);
      }
    }
  }

  fetchLivescore() {
    // this.requestService.getLivescore().subscribe({
    //   next: (v) => {

    //   },
    //   error: (e) => {
    //     console.log(e);
    //   },
    //   complete: () => { }
    // });
  }

  ngOnDestroy(): void {
    if (this.shakeIntervalId) {
      clearInterval(this.shakeIntervalId);
    }
    if (this.livescoreIntervalId) {
      clearInterval(this.livescoreIntervalId);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.filterPromotion();
      this.filterSlideshow();
      this.popularGames();
      this.fetchLivescore();
      register();
      this.livescoreIntervalId = setInterval(() => {
        this.fetchLivescore();
      }, 10000); // 10000 milliseconds = 10 seconds

      // Set interval for shaking elements every 10 minutes
      this.shakeIntervalId = setInterval(() => {
        this.shakeElements();
      }, 600000); // 600000 milliseconds = 10 minutes

      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }
}
