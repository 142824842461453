<div class="footer">
  @if(currentScreenSize !== 'xSmall'){
    <div class="container-xxl m-auto">
      <div class="bg-footer-top d-block">
        @if(websiteJSONData.misc.footer_information === 'ironman'){
          <img ngSrc="../../../template/green/assets/images/img-footer.webp" width="2652" height="178" priority class="w-100" />
        }@else{
          <img ngSrc="../../../template/green/assets/images/img-footer.png" width="2652" height="178" priority class="w-100" />
        }
      </div>
    </div>
  }@else{
    <div class="d-block container container-dp-wd">
      <div class="row align-items-center">
        <div class="col-6 text-left border-end align-self-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
              <p class="title-depo">DEPOSIT</p>
              <p class="desc-depo">Rata-rata waktu</p>
            </div>
            <span class="d-flex align-items-end">
              <p class="text-num-min pb-0">1</p>
              <p>min</p>
            </span>
          </div>
        </div>
        <div class="col-6 text-left align-self-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
              <p class="title-depo">WITHDRAW</p>
              <p class="desc-depo">Rata-rata waktu</p>
            </div>
            <span class="d-flex align-items-end">
              <p class="text-num-min pb-0">1.5</p>
              <p>min</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  }

  <div class="footer-list-bank">
    <app-slideshow-bank [data]="bankList"></app-slideshow-bank>
  </div>
  <div class="container-xxl m-auto">
    @for (prov of providerList; track prov; let index = $index){
    @if(prov.providers.length > 0){
    <div class="category-list">
      <p class="footer-category-title">{{ prov.tag }}</p>
      <div class="footer-category-list">
        @for (item of prov.providers; track item;) {
        <img
          [src]="
            '../../../template/green/assets/images/providers/' +
            (item.name | slugName) +
            '.webp'
          "
          [alt]="item.name | slugName"
        />
        }
      </div>
    </div>
    } @if(index !== providerList.length-1){
    <hr class="category-hr" />
    } }

    <mat-expansion-panel
      class="accordion-panel d-none d-md-block"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title #cardTitleFooter id="cardTitleFooter" class="accordion-title">
          <p #cardTitleTextFooter id="cardTitleTextFooter" class="accordion-title-inner" [ngClass]="cardTitleWidth < cardTitleTextWidth ? 'animation-footer': ''" [innerHTML]="footerHtml.title"></p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p [innerHTML]="footerHtml.body"></p>
    </mat-expansion-panel>
  </div>
  <div class="footer-bottom">
    <div class="row container-xxl m-auto">
      <div class="col-12 col-md-4">
        <p class="green-title">Bermain Dengan Bijak dan Bertanggung Jawab</p>
        <div class="row row-of-images">
          <img src="../../../template/green/assets/images/18+.png" alt="" />
          <img src="../../../template/green/assets/images/GT.png" alt="" />
          <img src="../../../template/green/assets/images/Gamcare.png" alt="" />
          <img
            src="../../../template/green/assets/images/Begamble.png"
            alt=""
          />
        </div>
      </div>
      <div class="col-12 col-md-8">
        <p class="green-title">Aman dan Terpercaya</p>
        <div class="row row-of-images">
          <img src="../../../template/green/assets/images/pagcor.png" alt="" />
          <img
            src="../../../template/green/assets/images/Gambling-commision.png"
            alt=""
          />
          <img src="../../../template/green/assets/images/Itech.png" alt="" />
          <img src="../../../template/green/assets/images/GLI.png" alt="" />
          <img src="../../../template/green/assets/images/BMM.png" alt="" />
          <img src="../../../template/green/assets/images/MGA.png" alt="" />
        </div>
      </div>
      <div class="col-12 mobile">
        <mat-expansion-panel
          class="accordion-panel d-block d-md-none mobile"
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title #cardTitleFooterSmall id="cardTitleFooterSmall" class="accordion-title">
              <p #cardTitleTextFooterSmall id="cardTitleTextFooterSmall" class="accordion-title-inner"
                [ngClass]="cardTitleSmallWidth < cardTitleTextSmallWidth ? 'animation-footer': ''" [innerHTML]="footerHtml.title"></p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p [innerHTML]="footerHtml.body"></p>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
  <!-- <div class="footer-last">
    <div
      class="container-xxl m-auto d-none d-md-flex align-items-center justify-content-between"
    >
      <div>
        <a [routerLink]="['/']">Beranda</a>
        <a [routerLink]="['/promotion']">Promosi</a>
        <a [routerLink]="['/referral']">Referral</a>
        <a [routerLink]="['/search']" [queryParams]="{'categories':('SL' | enCodeUri)}">Slots</a>
        <a [routerLink]="['/search']" [queryParams]="{'categories':('LC' | enCodeUri)}">Livecasino</a>
        <a [routerLink]="['/search']" [queryParams]="{'categories':('FH' | enCodeUri)}">Arcade</a>
        <a [routerLink]="['/livescore']">Livescore</a>
        <a [routerLink]="['/sitemap.xml']">Sitemap</a>
      </div>
      <p class="footer-text">
        ©2024, CASINO - Slot Website in Indonesia OK. All Rights Reserved.
      </p>
    </div>
    <div class="d-md-none d-flex align-items-center justify-content-between">
      <a [href]="websiteJSONData.configuration.apk_url" class="foot-mobile-nav">
        <img src="../../../template/green/assets/images/ic-apk.png" />
        <p>APK</p>
      </a>
      <a href="/promotion" class="foot-mobile-nav">
        <img src="../../../template/green/assets/images/ic-promosi.png" />
        <p>Promosi</p>
      </a>
      <a href="/" class="foot-mobile-nav">
        <img src="../../../template/green/assets/images/ic-beranda.png" />
        <p>Beranda</p>
      </a>
      <a href="/" class="foot-mobile-nav">
        <img src="../../../template/green/assets/images/ic-livechat.png" />
        <p>Livechat</p>
      </a>
      <a href="/login" class="foot-mobile-nav">
        <img src="../../../template/green/assets/images/ic-login.png" />
        <p>Masuk</p>
      </a>
    </div>
  </div> -->
  <div class="container-footer-bottom d-none d-lg-flex">
    <div class="container-xxl">
      <div class="row">
        <div class="col-6 text-left">
          <div class="footer-link ms-0">
            <a [routerLink]="['/']" >Beranda</a>
            <a [routerLink]="['/promotion']" >Promosi</a>
            @if(showReferral){
              <a [routerLink]="['/referral']" >Referral</a>
            }
            <a [routerLink]="['/search']" [queryParams]="{'categories': 'SL'|enCodeUri}">Slots</a>
            <a [routerLink]="['/search']" [queryParams]="{'categories': 'LC'|enCodeUri}" >Livecasino</a>
            <a [routerLink]="['/search']" [queryParams]="{'categories': 'FH'|enCodeUri}">Arcade</a>
            <a href="/sitemap.xml">Sitemap</a>
          </div>
        </div>
        <div class="col-6 text-center">
          <div class="footer-copyright">
            <!-- <img alt="Logo" src="{{imageLogo}}" /> -->
            <span>©{{currentYear}}, {{websiteJSONData.header.title}}. All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-footer-bottom d-block d-lg-none">
    <div class="container-xxl">
      <div class="row">
        <div class="col-12 text-center">
          <div class="footer-link">
            <a [routerLink]="['/']" >Beranda</a>
            <a [routerLink]="['/promotion']" >Promosi</a>
            @if(showReferral){
              <a [routerLink]="['/referral']">Referral</a>
            }
            <a [routerLink]="['/search']" [queryParams]="{'categories': 'SL'|enCodeUri}">Slots</a>
            <a [routerLink]="['/search']" [queryParams]="{'categories': 'LC'|enCodeUri}">Livecasino</a>
            <a [routerLink]="['/search']" [queryParams]="{'categories': 'FH'|enCodeUri}">Arcade</a>
            <a href="/sitemap.xml">Sitemap</a>
          </div>
        </div>
        <div class="col-12 text-center">
          <div class="footer-copyright justify-content-center">
            <!-- <img alt="Logo" src="{{imageLogo}}" /> -->
            <span>©{{currentYear}}, {{websiteJSONData.header.title}}. All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
