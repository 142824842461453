import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { RouterExtService } from '@componentpath/services/scripts.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faEye, faEyeSlash, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-mobile',
  standalone: true,
  imports: [FontAwesomeModule, FormsModule],
  templateUrl: './login-mobile.component.html',
  styleUrl: './login-mobile.component.scss'
})
export class LoginMobileComponent {
  faUser: IconProp = faUser;
  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;
  faLock: IconProp = faLock;
  faArrowLeft: IconProp = faArrowLeft;
  subscription: Subscription = new Subscription();

  websiteJSONData: any = this.data.websiteJSONData;

  username = '';
  password = '';
  isPasswordVisible = false;
  disabledLoginButton: boolean = false;
  prevURL: string = '';
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
    private data: DataService,
    private snackBar: MatSnackBar,
    private routerExtService: RouterExtService,
    private location: Location
  ) {
    this.prevURL = this.routerExtService.getPreviousUrl();
    // console.log(this.prevURL)
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toUppercase(event: string): void {
    this.username = event.toUpperCase();
  }

  backButton() {
    if (isPlatformBrowser(this.platformId)) {
      const currentUrl = window.location.href;
      const referrerUrl = document.referrer;

      const extractDomain = (url: string) => {
        try {
          const { hostname } = new URL(url);
          return hostname;
        } catch (e) {
          return null;
        }
      };

      const currentDomain = extractDomain(currentUrl);
      const referrerDomain = extractDomain(referrerUrl);

      if (currentDomain && referrerDomain && currentDomain === referrerDomain) {
        this.location.back();
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  login(): void {
    this.disabledLoginButton = true;
    if (isPlatformBrowser(this.platformId)) {
      if (!this.username || !this.password) {
        // this.toastService.show({
        //   template: this.errorToastTpl,
        //   classname: 'bg-danger text-light',
        //   delay: 8000,
        //   context: { message: 'Please complete all required fields.' },
        // });
        this.disabledLoginButton = false;
        return;
      }
      this.request.login(this.username, this.password).subscribe({
        next: (response) => {
          console.log(response);
          if (response.code === 200) {
            this.auth.setLogin(response.data, this.prevURL);
          } else {
            console.error('Login failed:', response.message);
            const message = response.message || 'Login failed';
            // this.toastService.show({
            //   template: this.errorToastTpl,
            //   classname: 'bg-danger text-light',
            //   context: { message },
            //   delay: 8000,
            // });
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: { message: message, type: 'mobile-error' },
              duration: 3000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: "notification-error"
            });
          }
        },
        error: (error) => {
          this.disabledLoginButton = false;
          const errorMessage =
            error.error.messages[0].message || 'Login failed';
          // this.toastService.show({
          //   template: this.errorToastTpl,
          //   classname: 'bg-danger text-light',
          //   context: { message: errorMessage },
          //   delay: 8000,
          // });
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: errorMessage, type: 'mobile-error' },
            duration: 3000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: "notification-error"
          });
        },
        complete: () => {
          // this.router.navigate(['/']);
          // console.log(this.auth.sessionValue);
        },
      });
    }
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          // console.log('login', v);
        },
      })
    );
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();
      this.auth.getUserData();
    }
    // this.promo();
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
