import {
  Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, PLATFORM_ID, Inject, Input, afterNextRender, ElementRef, ViewChild, OnDestroy,
} from '@angular/core';
import { isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { Swiper, SwiperOptions } from 'swiper/types';
import { A11y, Autoplay, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { register } from 'swiper/element/bundle';
import { Subscription } from 'rxjs';
import { ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-slideshow1',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgOptimizedImage, RouterModule],
  templateUrl: './slideshow1.component.html',
  styleUrl: './slideshow1.component.scss',
})
export class Slideshow1Component implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  @ViewChild('swiperContainer') swiperContainerRef!: ElementRef;
  @Input() data: any;

  slideshowJSONData: any;

  public configSlideshowSearch: SwiperOptions = {};
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute
  ) {
    this.swiperRender();
  }

  swiperRender() {
    afterNextRender((): void => {
      this.configSlideshowSearch = {
        loop: true,
        modules: [Autoplay],
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        mousewheel: false,
        effect: 'coverflow',
        grabCursor: true,
        spaceBetween: 100,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          slideShadows: true,
        },
        on: {
          init() {
            console.log('test init')
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      Object.assign(this.swiperContainerRef.nativeElement, this.configSlideshowSearch); // Add parameters to the Swiper
      this.swiperContainerRef.nativeElement.initialize(); // Init Swiper
      if (this.swiperContainerRef) {
        let swiper = this.swiperContainerRef.nativeElement.swiper as Swiper;
        setTimeout(() => {
          // swiper.slideTo(0, 200);
          swiper.autoplay.start();
        }, 300);
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      let providersData = this.data.providerJSONData;
      this.subscription.add(this.route.queryParams.subscribe((params) => {
        this.slideshowJSONData = []
        if (params['categories']) {
          let arrayCat = JSON.parse(decodeURIComponent(params['categories']));
          let currentCatgory = '';
          if (arrayCat[0] === 'SL'){
            currentCatgory = 'SLOT'
          }
          if (arrayCat[0] === 'LC'){
            currentCatgory = 'LIVECASINO'
          }
          if (arrayCat[0] === 'FH'){
            currentCatgory = 'ARCADE'
          }
          if (arrayCat[0] === 'SB'){
            currentCatgory = 'SPORTBOOK'
          }
          if (arrayCat[0] === 'ES'){
            currentCatgory = 'ESPORT'
          }
          // if (arrayCat[0] === 'LT'){
          //   currentCatgory = 'TOGEL'
          // }
          if (arrayCat.length === 1) {
            for (const value of this.data as Array<any>) {
              let tagsValue = value.tag as Array<string>;
              if (!value.global && tagsValue.includes(currentCatgory)) {
                this.slideshowJSONData.push(value);
              }
            }
          }
        }
        if (params['tags']) {
          if (JSON.parse(decodeURIComponent(params['tags']))[0] === 'CRASH'){
            let currentCatgory = 'SLOT';
  
            for (const value of this.data as Array<any>) {
              let tagsValue = value.tag as Array<string>;
              if (!value.global && tagsValue.includes(currentCatgory)) {
                this.slideshowJSONData.push(value);
              }
            }
          }
        }
        // if (params['tags'] && !params['providers']) {
        //   let arrayCat = JSON.parse(decodeURIComponent(params['tags']));
        //   if (arrayCat.length === 1) {
        //     for (const value of this.data as Array<any>) {
        //       let tagsValue = value.tag as Array<string>;
        //       if (!value.global && tagsValue.includes(arrayCat[0])) {
        //         this.slideshowJSONData.push(value);
        //       }
        //     }
        //   }
        // }
        // if (params['providers']) {
        //   let arrayCat = JSON.parse(decodeURIComponent(params['providers']));
        //   if (arrayCat.length === 1) {
        //     for (const value of this.data as Array<any>) {
        //       let tagsValue = value.tag as Array<string>;
        //       let index = providersData.findIndex((x: any) => x.code === arrayCat[0]);
        //       if (!value.global && tagsValue.includes((providersData[index]['name']).toUpperCase())) {
        //         this.slideshowJSONData.push(value);
        //       }
        //     }
        //   }
        // }
        if (this.swiperContainerRef) {
          let swiper =  this.swiperContainerRef.nativeElement.swiper as Swiper;
          console.log(this.swiperContainerRef.nativeElement)
          console.log(this.swiperContainerRef)
          // swiper.removeAllSlides()
          // Object.assign(this.swiperContainerRef.nativeElement, this.configSlideshowSearch);
          // swiper.init(this.swiperContainerRef.nativeElement);
          // swiper.updateSlides();
          setTimeout(() => {
            swiper.autoplay.stop();
            swiper.slideTo(0,200);
            swiper.autoplay.start();
          }, 300);
        }
      }));
      register();
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
