import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AuthService } from '@componentpath/services/auth.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MetaService } from '@componentpath/services/meta.service';
import { LoginComponent } from './login/login.component';
import { PinComponent } from './pin/pin.component';
import { RouterExtService, ScriptsService } from '@componentpath/services/scripts.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, NgbModule, LoginComponent, PinComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    title = 'orianna';
    bindingData: any;
    someInterval: any;
    subscription: Subscription = new Subscription();
    islogin: boolean = false;
    loaded: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private zone: NgZone,
        private data: DataService,
        private request: RequestService,
        private auth: AuthService,
        private cookie: SsrCookieService,
        private metaService: MetaService,
        private scriptsService: ScriptsService,
        private breakpointObserver: BreakpointObserver,
        private routerExtService: RouterExtService,
        public router: Router
    ) {
        this.routerExtService.getPreviousUrl();
    }

    private modalService = inject(NgbModal);
    url: string = '';

    close() {
        this.modalService.dismissAll();
    }

    ngOnInit(): void {
        this.auth.updateLoginStatus();
        this.data.getFileJSON();
        if (isPlatformBrowser(this.platformId)) {
            // document.addEventListener('contextmenu', event => event.preventDefault()); //disable right click
            // this.metaService.setMetaWebsite();
            this.scriptsService.createGoogleScript(this.data.websiteJSONData['script']['thirdparty']['google_analytic']);
            this.scriptsService.createMetaScript(this.data.websiteJSONData['script']['thirdparty']['facebook_id']);
            this.scriptsService.createKwaiScript(this.data.websiteJSONData['script']['thirdparty']['kwai_id']);
            this.scriptsService.createBigoScript(this.data.websiteJSONData['script']['thirdparty']['bigo_id']);
            this.scriptsService.createLivechat(this.data.websiteJSONData['contact']);
            this.scriptsService.createPureChat(this.data.websiteJSONData['contact']);
            this.scriptsService.createTawkto(this.data.websiteJSONData['contact']);
            this.auth.getUserData();
            if (this.auth.sessionValue){
                this.auth.callSession(this.auth.sessionValue);
            }
        }
    }
    ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            // this.subscription.unsubscribe();
        }
    }
}
