import { Component, Inject, PLATFORM_ID,ElementRef, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { SidebarMenuComponent } from '@template/green/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import { HeaderMobileComponent } from '@template/green/mobile/header-mobile/header-mobile.component';
import { Location } from '@angular/common';
import {faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterExtService } from '@componentpath/services/scripts.service';
@Component({
  selector: 'app-promotion-mobile',
  standalone: true,
  imports: [CommonModule,HeaderComponent, FooterComponent, SidebarMenuComponent,HeaderMobileComponent,FontAwesomeModule],
  templateUrl: './promotion-mobile.component.html',
  styleUrl: './promotion-mobile.component.scss'
})
export class PromotionMobileComponent {
  @ViewChild('topElement', { static: true }) topElement!: ElementRef;
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private routerExtService: RouterExtService
  ) { }

  prevURL: string = '';


  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  promo: any = null;
  promoJSONData: any = [];
  selectedPromo: any;
  faArrowLeft: IconProp = faArrowLeft;
  part = 'default';

  goBack(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.prevURL = this.routerExtService.getPreviousUrl();
      this.selectedPromo = null;
      // const currentUrl = window.location.href;
      // const referrerUrl = document.referrer;

      // const extractDomain = (url: string) => {
      //   try {
      //     const { hostname } = new URL(url);
      //     return hostname;
      //   } catch (e) {
      //     return null;
      //   }
      // };

      // const currentDomain = extractDomain(currentUrl);
      // const referrerDomain = extractDomain(referrerUrl);
      // console.log(currentDomain);
      // console.log(referrerDomain);
      // console.log(this.prevURL);
      // console.log(this.router.url);
      if(this.prevURL === this.router.url){
        if (this.part === 'detail') {
          this.router.navigate(['/promotion']);
        } else {
          this.router.navigate(['/']);
        }
      }else{
        if(this.part === 'default'){
          // console.log(this.part);
          this.router.navigate(['/']);
        }else{
          this.router.navigateByUrl(this.prevURL);
        }
      }
      // if (currentDomain && referrerDomain && currentDomain === referrerDomain) {
      // } else {
      //   this.router.navigate(['/']);
      // }
    }
  }

  public promoDetail(id: string): void {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'detail' } });
    if(this.selectedPromo != null && id == this.selectedPromo._id){
      this.selectedPromo = null;
    } else {
      if (isPlatformBrowser(this.platformId)) {
        for (const value of this.data.promoJSONData as any[]) {
          if (value._id == id) {
            this.selectedPromo = value;
          }
        }
      }
    }

  }


  //get only global false
  public filterPromotion() {
    for (const value of this.data.promoJSONData as any[]) {
      if (value.global == false) {
        this.promoJSONData.push(value);
      }
    }
  }

  ngOnInit(): void {
    this.router.navigate([this.router.url.split('?')[0]],{ queryParams: { part: 'default' } });
    this.activatedRoute.queryParams.subscribe(params => {
      if(params['part'] == 'detail'){
        this.part = 'detail';
      }
      else{
        this.part = 'default';
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.filterPromotion();
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }
}
