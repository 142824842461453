import { CommonModule,KeyValue } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleCheck, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-filter-mobile',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule],
  templateUrl: './filter-mobile.component.html',
  styleUrl: './filter-mobile.component.scss'
})

export class FilterMobileComponent {

  faCircleCheck = faCircleCheck;
  currentType = '';
  currentDate = '';
  faMagnifyingGlass = faMagnifyingGlass;
  inputSearch: string = '';
  currentSort: string = this.data.data.sortBy;
  filterProviders: boolean =  true;
  foundListProiders: boolean =  true;
  foundListCategory: boolean =  true;
  allCategoryActive: boolean = false;
  allFeatureActive: boolean = false;
  searchedList: Array<any> = this.data.data.listArray;
  filterProviderLists: Array<any> = [];
  featureList: Array<any> = [];

  compareFn = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    const order = ["", "83001", "83002", "83004", "50001", "50002", "700xx", "800xx", "801xx", "80201", "80202", "80203", "80204", "80205"];
    return order.indexOf(a.key) - order.indexOf(b.key);
  };

  compareDate = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    const order = ["", "today", "yesterday", "weekNow", "weekBefore", "monthNow"];
    return order.indexOf(a.key) - order.indexOf(b.key);
  };

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<FilterMobileComponent>
  ) {
    if (data.type == 'balance' || data.type == 'transaction'){
      this.currentType = data.data.currentFilter.type;
      this.currentDate = data.data.currentFilter.created_at;
    }

    if(data.type == 'statement'){
      this.filterProviderLists = data.data.providerList;
    }

    if(data.type == 'list-games'){
      let dataProvidersTrue = data.data.providers.find((x: any) => (x.checked === true));
      if(dataProvidersTrue){
        // let dataProvidersFalse = data.data.providers.find((x: any) => (x.checked === false));
        // if (!dataProvidersFalse) {
        //   this.foundListProiders = false;
        // } else {
        // }
        this.foundListProiders = true;
      }else {
        this.foundListProiders = false;
      }
      this.foundListCategory = data.data.categories.find((x: any) => (x.checked === true));
      if (!this.foundListCategory) {
        this.allCategoryActive = true;
      } else {
        this.allCategoryActive = false;
      }

      this.checkCategoriesListGames();

      for (let x = 0; x < this.featureList.length; x++) {
        for (let i = 0; i < data.data.feature.length; i++) {
          if(data.data.feature[i] === this.featureList[x]['code']){
            this.featureList[x]['checked'] = true;
          }
        }
      }

      let findCheckedFeature = this.featureList.find((x: any) => (x.checked === true));
      if (!findCheckedFeature) {
        this.allFeatureActive = true;
      } else {
        this.allFeatureActive = false;
      }
    }
  }

  checkCategoriesListGames(){
    this.featureList = [];
    let dataFeature:any = [];
    let featureSL:any = [];
    let featureLC:any = [];
    if (this.data.data.categories.find((x: any) => (x.code_small === 'SL' && x.checked === true))) {
      featureSL = [
        {
          "name": 'Buy-In Games',
          'code': 'BUYIN'
        },
        {
          "name": 'Jackpot Games',
          'code': 'JACKPOT'
        },
        {
          "name": 'Bonanza Games',
          'code': 'BONANZA'
        },
        {
          "name": 'Megaways Games',
          'code': 'MEGAWAYS'
        }
      ]
      dataFeature.push(featureSL);
      // this.allFeatureActive = true;
    }
    if (this.data.data.categories.find((x: any) => (x.code_small === 'LC' && x.checked === true))) {
      featureLC = [
        {
          "name": 'Game Shows',
          'code': 'GAMESHOW'
        },
        {
          "name": 'Baccarat',
          'code': 'BACCARAT'
        },
        {
          "name": 'Blackjack',
          'code': 'BLACKJACK'
        },
        {
          "name": 'Roullete',
          'code': 'ROULLETE'
        }
      ]
      dataFeature.push(featureLC);
      // this.allFeatureActive = true;
    }
    if (this.data.data.categories.find((x: any) => (x.code_small === 'FH' && x.checked === true))) {
      dataFeature.push([]);
    }
    if (this.data.data.categories.find((x: any) => (x.code_small === 'SB' && x.checked === true))) {
      dataFeature.push([]);
    }
    if (this.data.data.categories.find((x: any) => (x.code_small === 'ES' && x.checked === true))) {
      dataFeature.push([]);
    }
    if (this.data.data.categories.find((x: any) => (x.code_small === 'LT' && x.checked === true))) {
      dataFeature.push([]);
    }
    // this.featureList.push(...featureSL);
    // console.log(dataFeature)
    if (dataFeature.length >= 1) {
      this.featureList = dataFeature[0];
    }

    if ((this.data.data.categories.filter((x: any) => (x.checked === true))).length > 1) {
      if(dataFeature.length > 1){
        const arrayOfIdSets = dataFeature.map((arr:any) =>
          new Set(arr.map((val:any) => val.code))
        );
        const smallestArray = dataFeature.reduce((smallest:any, arr:any) =>
          smallest.length < arr.length ? smallest : arr
        );
        const intersection = smallestArray.filter((val:any) =>
          arrayOfIdSets.every((set:any) => set.has(val.code))
        );
        // console.log(intersection)
        this.featureList = intersection;
      }
    }

    this.allFeatureActive = true;
    // if ((this.data.data.categories.filter((x: any) => (x.checked === true))).length > 1) {
    //   const duplicates: any = [];
    //   for (let i = 0; i < this.featureList.length; i++) {
    //     for (let j = i + 1; j < this.featureList.length; j++) {
    //       if (this.featureList[i].code === this.featureList[j].code && !duplicates.includes(this.featureList[i].code)) {
    //         duplicates.push(this.featureList[i]);
    //       }
    //     }
    //   }
    //   console.log(duplicates)
    //   this.featureList = duplicates;
    // }
  }

  onTyping(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.inputSearch = input.value.toUpperCase();
  }

  selectTypeBank(val:string): void {
    this.bottomSheetRef.dismiss(val);
  }

  selectProvider(val:string): void {
    this.bottomSheetRef.dismiss(val);
  }

  selectProviderGame(item:any){
    item.checked = !item.checked;
    let dataProvidersTrue = this.data.data.providers.find((x: any) => (x.checked === true));
    if (dataProvidersTrue) {
      // let dataProvidersFalse = data.data.providers.find((x: any) => (x.checked === false));
      // if (!dataProvidersFalse) {
      //   this.foundListProiders = false;
      // } else {
      // }
      this.foundListProiders = true;
    } else {
      this.foundListProiders = false;
    }
  }
  selectCategoryGame(item:any){
    item.checked = !item.checked;
    this.foundListCategory = this.data.data.categories.find((x: any) => (x.checked === true));
    if(!this.foundListCategory){
      this.allCategoryActive = true;
    }else{
      this.allCategoryActive = false;
    }
    this.checkCategoriesListGames();
  }
  selectFeatureGame(item:any){
    item.checked = !item.checked;
    let findCheckedFeature = this.featureList.find((x: any) => (x.checked === true));
    if (!findCheckedFeature) {
      this.allFeatureActive = true;
    } else {
      this.allFeatureActive = false;
    }
  }
  selectSortGame(item:any){
    this.currentSort = item;
  }

  setAllCategory(){
    this.allCategoryActive = true;
    for (let i = 0; i < this.data.data.categories.length; i++) {
      this.data.data.categories[i].checked = false;
    }
    this.checkCategoriesListGames();
  }
  setAllFeature(){
    this.allFeatureActive = true;
    if(this.featureList.length > 0){
      for (let i = 0; i < this.featureList.length; i++) {
        this.featureList[i].checked = false;
      }
    }
  }

  resetFilterGame(){
    for (let i = 0; i < this.data.data.categories.length; i++) {
      this.data.data.categories[i].checked = false; 
    }
    for (let i = 0; i < this.data.data.providers.length; i++) {
      this.data.data.providers[i].checked = false; 
    }
    let data = {
      providers: [''],
      categories: [''],
      feature: [''],
      sortBy: ''
    }
    this.bottomSheetRef.dismiss(data);
  }
  submitFilterGame(){
    let dataProvidersTrue = this.data.data.providers.filter((x: any) => (x.checked === true));
    let dataCategoriesTrue = this.data.data.categories.filter((x: any) => (x.checked === true));
    let dataFeatureTrue = this.featureList.filter((x: any) => (x.checked === true));
    let categoriesArr:any = [];
    let providersArr:any = [];
    let featureArr:any = [];
    for (let i = 0; i < dataProvidersTrue.length; i++) {
      const categoriesID = dataProvidersTrue[i]['code'];
      providersArr.push(categoriesID);
    }

    for (let i = 0; i < dataCategoriesTrue.length; i++) {
      const categoriesID = dataCategoriesTrue[i]['code_small'];
      categoriesArr.push(categoriesID);
    }

    for (let i = 0; i < dataFeatureTrue.length; i++) {
      const featureCode = dataFeatureTrue[i]['code'];
      featureArr.push(featureCode);
    }

    let data = {
      providers:providersArr,
      categories:categoriesArr,
      feature:featureArr,
      dataFeatureTrue: dataFeatureTrue,
      sortBy:this.currentSort
    }
    this.bottomSheetRef.dismiss(data);
  }


  selectButtonType(value: any) {
    this.currentType = value;
  }

  selectButtonDate(value: any) {
    this.currentDate = value;
  }

  resetFilter() {
    let currentFilter = {
      type: '',
      created_at : ''
    }
    this.bottomSheetRef.dismiss(currentFilter);
  }

  submitFilter() {
    let currentFilter = {
      type: this.currentType,
      created_at : this.currentDate
    }
    this.bottomSheetRef.dismiss(currentFilter);
  }

  onSearchValue(e: Event) {
    const input = this.inputSearch.toLowerCase();
    let typeLists: Array<any> = [];
    typeLists = this.data.data.listArray;

    this.searchedList = typeLists.filter((option) =>
      (option['name'].toLowerCase().includes(input))
    );

    if (!input) {
      this.searchedList = [...typeLists];
    }
  }

  onSearchProvider(event: any) {
      const input = this.inputSearch.toLowerCase();
      if (!input) {
        this.filterProviderLists = [...this.data.data.providerList];
        return;
      }
      const list = this.data.data.providerList as Array<any>;
      const filteredList = list.filter((provider) =>
          provider.name.toLowerCase().includes(input)
      );
      this.filterProviderLists = filteredList;
  }

  selectCreateBank(val: string): void {
    this.bottomSheetRef.dismiss(val);
  }
}
