<div
  class="notification-container"
  (click)="snackBarRef.dismissWithAction()"
  [ngClass]="{
    success: data.type === 'success',
    error: data.type === 'error',
    default: data.type === 'default'
  }"
>
<div>
  <span class="message" matSnackBarLabel>
    @if(data.type == 'success'){
    <img
      src="../template/green/assets/images/success.webp"
      class="img-fluid icon"
    />
    } @else if(data.type == 'error'){
    <img
      src="../template/green/assets/images/error.webp"
      class="img-fluid icon"
    />
    } @else {
    <img
      src="../template/green/assets/images/information.webp"
      class="img-fluid icon"
    />
    }
      {{ data.message }}
    </span>
</div>
<div class="close-container">
  @if(data.type == 'mobile-success' || data.type == 'mobile-error' || data.type == 'mobile-default'){
    <img
    src="../template/green/assets/images/mobile/x.webp"
    class="img-fluid close-button"
  />
  }
</div>


</div>
