@if(currentScreenSize === 'xSmall' || currentScreenSize === 'smallOne'){
    <div class="container-games-widget display-widget-mobile container-xxl">
        <div class="crash-games-widget">
            <div class="box-widget">
                <div class="banner-top">
                    <div class="title-widget">CRASH GAMES</div>
                    <img src="../../../../template/green/assets/images/crash-banner-widget-mobile.webp">
                </div>
                <div class="container-games">
                    <div class="games-right" style="cursor: pointer;">
                        @for (item of listGameCrash; track item) {
                        <a (click)="clickPlay(item)">
                            <img src="../../../../template/green/assets/images/{{item['title'] | slugName}}-widget.webp"
                                [alt]="item['title'] | slugName">
                        </a>
                        }
                    </div>
                </div>
            </div>
        </div>

        <div class="slot-games-widget">
            <div class="box-widget">
                <div class="banner-top">
                    <div class="title-widget">SLOT GAMES</div>
                    <img src="../../../../template/green/assets/images/slot-banner-widget-mobile.webp">
                </div>
                <div class="container-games">
                    <div class="text-top">
                        <div class="navigation-slot-prev" (click)="prevSlot()"> < Prev </div>
                                <div class="provider-name">{{slotProviders.providers[slotIndex]['name']}}</div>
                                <div class="navigation-slot-next" (click)="nextSlot()">Next ></div>
                        </div>
                        <div class="list-games">
                            <swiper-container init="false" #swiperSlotGames class="swiper-container">
        
                                @for (item of listGamesSlot; track item) {
                                <swiper-slide class="swiper-item w-auto">
                                    <div class="card card-game" (click)="clickPlay(item)">
                                        <div class="card-game-top">
                                            <div class="tags-game">
                                                @for(tag of item['tags']; track tag;){
                                                @if(item['tags'].length > 0 && $index < 3 && tag !=='' ){ <span
                                                    [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'">
                                                    {{tag | tagName}} </span>
                                                    }
                                                    }
                                            </div>
                                            <div class="ratio ratio-1x1">
                                                @if(item['image'] !== '' && item['image'] !== undefined) {
                                                <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200"
                                                    class="card-img-top" />
                                                } @else() {
                                                <div class="no-image">No Image</div>
                                                }
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div>
                                                <div class="container-title">
                                                    <div #cardTitleWidget class="h5 card-title" id="{{item._id}}"
                                                        [ngClass]="{'animation-test': cardTitleWidget ? cardTitleWidth > (120 - 32) : false}">
                                                        {{ item['title'] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex card-body-bottom">
                                            <button>Play</button>
                                        </div>
                                    </div>
                                </swiper-slide>
                                }
                            </swiper-container>
                        </div>
                    </div>
                </div>
        </div>
        <div class="show-games-widget">
            <div class="box-widget">
                <div class="banner-top">
                    <div class="title-widget">GAME SHOW</div>
                    <img src="../../../../template/green/assets/images/show-banner-widget-mobile.webp">
                </div>
                <div class="container-games">
                    <div class="list-games">
                        <swiper-container init="false" #swiperShowGames class="swiper-container">
        
                            @for (item of listGameShow; track item) {
                            <swiper-slide class="swiper-item w-auto">
                                <div class="card card-game" (click)="clickPlay(item)">
                                    <div class="card-game-top">
                                        <div class="ratio ratio-1x1">
                                            @if(item['image'] !== '' && item['image'] !== undefined) {
                                            <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200"
                                                class="card-img-top" />
                                            } @else() {
                                            <div class="no-image">No Image</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                            }
                        </swiper-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="arcade-games-widget">
            <div class="box-widget">
                <div class="banner-top">
                    <div class="title-widget">ARCADE GAMES</div>
                    <img src="../../../../template/green/assets/images/arcade-banner-widget-mobile.webp">
                </div>
                <div class="container-games">
                    <div class="text-top">
                        <div class="navigation-slot-prev" (click)="prevArcade()">< Prev</div>
                        <div class="provider-name">{{arcadeProviders.providers[arcadeIndex]['name']}}</div>
                        <div class="navigation-slot-next" (click)="nextArcade()">Next ></div>
                    </div>
                    <div class="list-games">
                        <swiper-container init="false" #swiperArcadeGames class="swiper-container">
    
                            @for (item of listGamesArcade; track item) {
                            <swiper-slide class="swiper-item w-auto">
                                <div class="card card-game" (click)="clickPlay(item)">
                                    <div class="card-game-top">
                                        <div class="tags-game">
                                            @for(tag of item['tags']; track tag;){
                                            @if(item['tags'].length > 0 && $index < 3 && tag !=='' ){ <span
                                                [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'">
                                                {{tag | tagName}} </span>
                                                }
                                                }
                                        </div>
                                        <div class="ratio ratio-1x1">
                                            @if(item['image'] !== '' && item['image'] !== undefined) {
                                            <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200"
                                                class="card-img-top" />
                                            } @else() {
                                            <div class="no-image">No Image</div>
                                            }
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div>
                                            <div class="container-title">
                                                <div #cardTitle class="h5 card-title"
                                                    [ngClass]="{'animation-test': cardTitle ? cardTitleWidth > (120 - 32) : false}">
                                                    {{
                                                    item['title'] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex card-body-bottom">
                                        <button>Play</button>
                                    </div>
                                </div>
                            </swiper-slide>
                            }
                        </swiper-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
}@else{
    <div class="container-games-widget container-xxl">
        <swiper-container init="false" #swiperContainerWidget class="swiper-container">
            <swiper-slide class="swiper-item w-auto">
                <div class="crash-games-widget">
                    <div class="box-widget">
                        <div class="banner-top">
                            <div class="title-widget">CRASH GAMES</div>
                            <img src="../../../../template/green/assets/images/crash-banner-widget.webp">
                        </div>
                        <div class="container-games">
                            <div class="games-right" style="cursor: pointer;">
                                @for (item of listGameCrash; track item) {
                                    <a (click)="clickPlay(item)">
                                        <img src="../../../../template/green/assets/images/{{item['title'] | slugName}}-widget.webp" [alt]="item['title'] | slugName">
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper-item w-auto">
                <div class="slot-games-widget">
                    <div class="box-widget">
                        <div class="banner-top">
                            <div class="title-widget">SLOT GAMES</div>
                            <img src="../../../../template/green/assets/images/slot-banner-widget.webp">
                        </div>
                        <div class="container-games">
                            <div class="text-top">
                                <div class="navigation-slot-prev" (click)="prevSlot()">< Prev</div>
                                <div class="provider-name">{{slotProviders.providers[slotIndex]['name']}}</div>
                                <div class="navigation-slot-next" (click)="nextSlot()">Next ></div>
                            </div>
                            <div class="list-games">
                                <swiper-container init="false" #swiperSlotGames class="swiper-container">
            
                                    @for (item of listGamesSlot; track item) {
                                        <swiper-slide class="swiper-item w-auto">
                                            <div class="card card-game" (click)="clickPlay(item)">
                                                <div class="card-game-top">
                                                    <div class="tags-game">
                                                        @for(tag of item['tags']; track tag;){
                                                            @if(item['tags'].length > 0 && $index < 3 && tag !=='' ){ 
                                                                <span  [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'"> {{tag | tagName}} </span>
                                                            }
                                                        }
                                                    </div>
                                                    <div class="ratio ratio-1x1">
                                                        @if(item['image'] !== '' && item['image'] !== undefined) {
                                                        <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200" class="card-img-top" />
                                                        } @else() {
                                                        <div class="no-image">No Image</div>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div>
                                                        <div class="container-title">
                                                            <div #cardTitleWidget class="h5 card-title" id="{{item._id}}"
                                                                [ngClass]="{'animation-test': cardTitleWidget ? cardTitleWidth > (120 - 32) : false}">{{ item['title'] }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex card-body-bottom">
                                                    <button>Play</button>
                                                </div>
                                            </div>
                                        </swiper-slide>
                                    }
                                </swiper-container>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper-item w-auto">
                <div class="show-games-widget">
                    <div class="box-widget">
                        <div class="banner-top">
                            <div class="title-widget">GAME SHOW</div>
                            <img src="../../../../template/green/assets/images/show-banner-widget.webp">
                        </div>
                        <div class="container-games">
                            <div class="list-games">
                                <swiper-container init="false" #swiperShowGames class="swiper-container">
                            
                                    @for (item of listGameShow; track item) {
                                    <swiper-slide class="swiper-item w-auto">
                                        <div class="card card-game" (click)="clickPlay(item)">
                                            <div class="card-game-top">
                                                <div class="ratio ratio-1x1">
                                                    @if(item['image'] !== '' && item['image'] !== undefined) {
                                                    <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200"
                                                        class="card-img-top" />
                                                    } @else() {
                                                    <div class="no-image">No Image</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    }
                                </swiper-container>
                            </div>
                        </div>
                    </div>
                </div>
    
            </swiper-slide>
            <swiper-slide class="swiper-item w-auto">
                <div class="arcade-games-widget">
                    <div class="box-widget">
                        <div class="banner-top">
                            <div class="title-widget">ARCADE GAMES</div>
                            <img src="../../../../template/green/assets/images/arcade-banner-widget.webp">
                        </div>
                        <div class="container-games">
                            <div class="text-top">
                                <div class="navigation-slot-prev"(click)="prevArcade()">< Prev</div>
                                <div class="provider-name">{{arcadeProviders.providers[arcadeIndex]['name']}}</div>
                                <div class="navigation-slot-next" (click)="nextArcade()">Next ></div>
                            </div>
                            <div class="list-games">
                                <swiper-container init="false" #swiperArcadeGames class="swiper-container">
                            
                                    @for (item of listGamesArcade; track item) {
                                    <swiper-slide class="swiper-item w-auto">
                                        <div class="card card-game" (click)="clickPlay(item)">
                                            <div class="card-game-top">
                                                <div class="tags-game">
                                                    @for(tag of item['tags']; track tag;){
                                                    @if(item['tags'].length > 0 && $index < 3 && tag !=='' ){ <span
                                                        [ngClass]="tag === 'POPULAR'? '': tag === 'NEW'? 'blue-tag': tag === 'BUYIN' || tag === 'LINKWIN' ? 'purple-tag':'green-tag'">
                                                        {{tag | tagName}} </span>
                                                        }
                                                        }
                                                </div>
                                                <div class="ratio ratio-1x1">
                                                    @if(item['image'] !== '' && item['image'] !== undefined) {
                                                    <img [ngSrc]="item['image']" [alt]="item['title']" width="200" height="200"
                                                        class="card-img-top" />
                                                    } @else() {
                                                    <div class="no-image">No Image</div>
                                                    }
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div>
                                                    <div class="container-title">
                                                        <div #cardTitle class="h5 card-title"
                                                            [ngClass]="{'animation-test': cardTitle ? cardTitleWidth > (120 - 32) : false}">{{
                                                            item['title'] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex card-body-bottom">
                                                <button>Play</button>
                                            </div>
                                        </div>
                                    </swiper-slide>
                                    }
                                </swiper-container>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
}
