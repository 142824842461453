import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { afterNextRender, AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardGameComponent } from '../card-game/card-game.component';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { DataService } from '@componentpath/services/data.service';
import { register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { SlugNamePipe, TagNamePipe } from '@componentpath/pipes/slug-name.pipe';
import { AuthService } from '@componentpath/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-games-widget',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    CardGameComponent,
    NgbDropdownModule,
    MatCheckboxModule,
    FormsModule,
    TagNamePipe,
    SlugNamePipe,
    NgOptimizedImage],
  templateUrl: './games-widget.component.html',
  styleUrl: './games-widget.component.scss'
})
export class GamesWidgetComponent implements OnInit, AfterViewInit {

  @ViewChild('cardTitleWidget') cardTitleWidget!: ElementRef;

  @ViewChild('swiperSlotGames') swiperSlotGames!: ElementRef;
  @ViewChild('swiperArcadeGames') swiperArcadeGames!: ElementRef;
  @ViewChild('swiperShowGames') swiperShowGames!: ElementRef;
  @ViewChild('swiperContainerWidget') swiperContainerWidget!: ElementRef;

  public configSwiperSlot: SwiperOptions = {};
  public configSwiperArcade: SwiperOptions = {};
  public configSwiperShow: SwiperOptions = {};
  public configSwiperWidget: SwiperOptions = {};

  slotProviders : any = [];
  slotIndex: number = 0;
  listGamesSlot: any =[];

  arcadeProviders : any = [];
  arcadeIndex: number = 0;
  listGamesArcade: any =[];
  listGameCrash: any =[];
  crashGame: Record<string, any> = {}

  listGameShow: any =[];


  cardTitleWidth: number = 0;
  currentScreenSize: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ){
    this.swiperRender();
    this.checkScreen();
  }

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 699.98px)", 'smallOne'],
      ["(min-width: 700px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 699.98px)",
      "(min-width: 700px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  swiperRender() {
    afterNextRender((): void => {
      this.configSwiperSlot = {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        mousewheel: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      this.configSwiperWidget = {
        loop: false,
        autoplay: false,
        mousewheel: false,
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      this.configSwiperArcade = {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        mousewheel: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };
      this.configSwiperShow = {
        loop: false,
        scrollbar: true,
        mousewheel: true,
        slidesPerView: 2,
        slidesPerGroup: 6,
        grid:{
          rows: 2,
          fill: 'row'
        },
        spaceBetween: 0,
        freeMode: true,
        pagination: false,
        navigation: false,
        on: {
          init() {
            // Here you can add some functions after initializing the swiper
          },
        },
      };

      Object.assign(this.swiperArcadeGames.nativeElement, this.configSwiperArcade); // Add parameters to the Swiper
      this.swiperArcadeGames.nativeElement.initialize(); // Init Swiper

      Object.assign(this.swiperShowGames.nativeElement, this.configSwiperShow); // Add parameters to the Swiper
      this.swiperShowGames.nativeElement.initialize(); // Init Swiper

      Object.assign(this.swiperSlotGames.nativeElement, this.configSwiperSlot); // Add parameters to the Swiper
      this.swiperSlotGames.nativeElement.initialize(); // Init Swiper

      if (this.swiperContainerWidget){
        Object.assign(this.swiperContainerWidget.nativeElement, this.configSwiperWidget); // Add parameters to the Swiper
        this.swiperContainerWidget.nativeElement.initialize(); // Init Swiper
      }
    });
  }

  clickPlay(info:any) {
    if (this.auth.sessionValue) {
      this.router.navigate([`/game/${info.game_id}`]);
    } else {
      this.router.navigate(['/login']);
    }
  }

  getGames(total: number, type:string, provider: string, tag: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, total, [provider], [type], [tag], '', 'popularity_point','desc').subscribe({
          next: (v) => {
            console.log(v)
            if(type === 'SL'){
              this.listGamesSlot = v['items'];
            }
            if(type === 'FH'){
              this.listGamesArcade = v['items'];
            }
            if(type === 'LC'){
              this.listGameShow = v['items'];
            }
            if(tag === 'CRASH'){
              if (v['items'].length > 0){
                this.listGameCrash = v['items'];
              }
            }
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => {
          },
        });
    }
  }

  prevSlot(){
    if (this.slotIndex <= 0){
      this.slotIndex = 0
    } else{
      this.slotIndex =  this.slotIndex - 1;
    }
    this.getGames(10, 'SL', this.slotProviders.providers[this.slotIndex]['_id'],'');
  };

  nextSlot(){
    if (this.slotIndex >= (this.slotProviders.providers.length - 1)) {
      this.slotIndex = (this.slotProviders.providers.length - 1)
    } else {
      this.slotIndex = this.slotIndex + 1;
    }
    this.getGames(10, 'SL', this.slotProviders.providers[this.slotIndex]['_id'], '');
  };

  prevArcade(){
    if (this.arcadeIndex <= 0){
      this.arcadeIndex = 0
    } else{
      this.arcadeIndex = this.arcadeIndex - 1;
    }
    this.getGames(10, 'FH', this.arcadeProviders.providers[this.arcadeIndex]['_id'],'');
  };

  nextArcade(){
    if (this.arcadeIndex >= (this.arcadeProviders.providers.length - 1)) {
      this.arcadeIndex = (this.arcadeProviders.providers.length - 1)
    } else {
      this.arcadeIndex = this.arcadeIndex + 1;
    }
    this.getGames(10, 'FH', this.arcadeProviders.providers[this.arcadeIndex]['_id'], '');
  };


  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.slotProviders = (this.dataService.categoryproviderJSONData as Array<any>).find(
        (value: any) => value.code_small === 'SL'
      );
      this.getGames(10, 'SL', this.slotProviders.providers[this.slotIndex]['_id'], '');
      
      this.arcadeProviders = (this.dataService.categoryproviderJSONData as Array<any>).find(
        (value: any) => value.code_small === 'FH'
      );
      this.getGames(10, 'FH', this.arcadeProviders.providers[this.slotIndex]['_id'], '');
      this.getGames(10, 'LC', '', 'GAMESHOW');
      this.getGames(10, '', '', 'CRASH');
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (this.cardTitleWidget && this.listGamesSlot.length > 0 && this.listGamesArcade.length > 0) {
          console.log(this.cardTitleWidget.nativeElement.clientWidth);
          this.cardTitleWidth = this.cardTitleWidget.nativeElement.clientWidth;
          this.cdr.detectChanges();
        }
      }, 1000);
      register();
    }
  }

}
